/*
 * (c) Meta Platforms, Inc. and its affiliates.
 *
 */

import React from "react";
import { FaFacebook } from "react-icons/fa";
import { IoIosLink, IoLogoTwitter } from "react-icons/io";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { useCopyToClipboard } from "react-use";

interface SharingContainerProps {
	variant?: "light" | "dark";
	title: string;
	text: string;
	shareableImgFile?: File | null;
}

const SharingContainer = ({
	variant,
	title,
	text,
	shareableImgFile,
}: SharingContainerProps) => {
	const [, copyToClipboard] = useCopyToClipboard();

	/**
	 * Generate url based on origin + path + search.
	 * @returns
	 */
	const getLinkToShare = () => {
		let shareLink = window.location.href;
		return shareLink;
	};

	/**
	 * Handles sharing using the Navigator share API.
	 */
	const handleShare = async () => {
		const shareData = {
			url: getLinkToShare(),
			text: text,
			title: title,
		};

		const data = shareableImgFile
			? { ...shareData, files: [shareableImgFile!] }
			: shareData;

		try {
			if (!navigator.canShare(data)) {
				console.error("Can't share natively");
			}
			await navigator.share(data);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<>
			{navigator.share === undefined ? (
				<div
					className={`w-32 lg:w-[10rem] h-[2.25rem] border-2 rounded-lg flex justify-evenly items-center text-xs lg:text-sm ${
						variant === "light" && "text-primary"
					}`}
				>
					Share
					<TwitterShareButton url={getLinkToShare()} title={title}>
						<IoLogoTwitter size={"1.25em"} />
					</TwitterShareButton>
					<FacebookShareButton
						url={getLinkToShare()}
						quote={`${title}, ${text}`}
					>
						<FaFacebook size={"1.25em"} />
					</FacebookShareButton>
					<IoIosLink
						className="cursor-pointer"
						size={"1.25em"}
						onClick={() => copyToClipboard(getLinkToShare())}
					/>
				</div>
			) : (
				<button
					className={`w-32 lg:w-[10rem] h-[2.25rem] border lg:border-2 rounded-lg flex justify-evenly items-center text-xs lg:text-sm ${
						variant === "light" && "text-primary"
					}`}
					onClick={handleShare}
				>
					Share
				</button>
			)}
		</>
	);
};

export default SharingContainer;
