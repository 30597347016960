/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import HeatMap from "../charts/HeatMap";
import { HeatMapDataPoint } from "../../utils/Helpers";

interface PredictedErrorCardProps {
	data: HeatMapDataPoint[] | undefined;
	variant?: "light" | "dark";
}

const PredictedErrorCard = ({ data, variant }: PredictedErrorCardProps) => {
	return (
		<div className="relative h-full w-full max-w-full">
			<div
				className={`h-full p-2 overflow-auto ${
					variant === "light" ? "text-primary" : "text-white"
				}`}
			>
				<div className="py-5 px-1">
					<h2 className="mt-1 max-w-2xl text-xl">Predicted Aligned Error</h2>
				</div>

				<div className="flex flex-col px-1 lg:px-6 py-1">
					<div className="relative max-h-[310px] max-w-[310px] lg:max-h-[580px] lg:max-w-[580px] m-auto overflow-auto">
						<HeatMap data={data as HeatMapDataPoint[]} variant={variant} />
					</div>

					<div
						className={`relative px-6 py-2 mx-auto ${
							variant === "light" ? "bg-gray" : "bg-base-300/60"
						}`}
					>
						<div className="flex flex-row justify-between mb-2 w-20 lg:w-40">
							<p className="font-bold text-xs lg:text-base">0</p>
							<p className="font-bold text-xs lg:text-base">15</p>
							<p className="font-bold text-xs lg:text-base">30</p>
						</div>
						<div className="flex flex-row w-20 lg:w-40 ">
							<div
								className="p-1 w-full"
								style={{ backgroundColor: "green" }}
							></div>
							<div
								className="p-1 mx-2 w-full"
								style={{ backgroundColor: "#73BE73" }}
							></div>
							<div
								className="p-1 w-full"
								style={{ backgroundColor: "white" }}
							></div>
						</div>
					</div>
				</div>

				<div className="flex flex-col px-6 py-1">
					<div className="flex flex-row">
						Predicted aligned error is the estimated error distance in angstroms
						between pairs of residues in the structure.{" "}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PredictedErrorCard;
