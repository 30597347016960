/*
 * (c) Meta Platforms, Inc. and its affiliates.
 *
 */

import React, { useReducer } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DataContext, DataPoint } from "./context/DataContext";
import SplashPage from "./components/pages/SplashPage";
import ExplorerPage from "./components/pages/ExplorerPage";
import DetailPage from "./components/pages/DetailPage";
import LightDetailPage from "./components/pages/LightDetailPage";
import FoldSeqDetailPage from "./components/pages/FoldSeqDetailPage";
import AboutPage from "./components/pages/AboutPage";
import ResourcesPage from "./components/pages/ResourcesPage";
import SearchStructurePage from "./components/pages/SearchStructurePage";
import SearchSequencePage from "./components/pages/SearchSequencePage";
import MobileExplorerPage from "./components/pages/MobileExplorerPage";
import { isMobile } from "react-device-detect";
import { useLinkTag, useScript } from "./hooks";
import axios from "axios";
import { getFloat16 } from "@petamoriken/float16";

import DataLoader from "dataloader";

function App() {
	const [data, dispatch] = useReducer((prevState: any, action: any) => {
		// new state adds the new points
		return [...prevState, ...action.points];
	}, []);
	const [pdbDataUrl, setPdbDataUrl] = React.useState<string | URL>("");
	const [loadingPoints, setLoadingPoints] = React.useState<boolean>(false);

	/**
	 *
	 * @param dataView a data view on an underlying ArrayBuffer
	 * @returns
	 */
	function dataPointFromView(dataView: DataView): DataPoint {
		let x = getFloat16(dataView, 0);
		let y = getFloat16(dataView, 2);
		let novelty = getFloat16(dataView, 4);
		let id = dataView.getUint32(6);
		// recompose the id from the underlying int
		let mginifyId = "MGYP" + id.toString().padStart(12, "0");
		// note v7 onwards, novelty is properly scaled in [0, 1] in csv file
		//let novelty_rescaled = (novelty - 0.299) / (0.701)

		const dataPoint = {
			id: 0,
			x: x,
			y: y,
			mgnifyID: mginifyId,
			//score: novelty_rescaled,
			score: novelty,
		};
		return dataPoint;
	}

	/**
	 * Loads static data on page mount
	 */
	React.useLayoutEffect(() => {
		setLoadingPoints(true);
		const urls = process.env.REACT_APP_DATA_FILES || "";

		async function batchLoadUrls(urls: any) {
			const results = new Array<any>(urls.length);
			for (let index = 0; index < urls.length; index++) {
				results[index] = await loadDataFromUrl(urls[index]);
			}
			setLoadingPoints(false);
			return (urls as string[]).map(
				(key, i) => results[i] || new Error(`No result for ${key}`)
			);
		}

		const loadDataFromUrl = (url: string) =>
			axios
				.get("./" + url, { responseType: "arraybuffer" })
				.then((response) => {
					let buffer = response.data;

					var offset = 0;
					var index = 0;
					let formattedData = [];

					while (offset < buffer.byteLength) {
						var dataView = new DataView(buffer.slice(offset, offset + 10));
						const dataPoint = dataPointFromView(dataView);
						dataPoint.id = index;

						formattedData.push(dataPoint);

						offset += 10;
						index += 1;
					}

					dispatch({ points: formattedData });
				});

		// Note: maxBatchSize seems to be misleading this represents the total number of sequential batches
		// that the loader will create rather than the max number of parallel requests.
		const pointsLoader = new DataLoader(batchLoadUrls, { maxBatchSize: 10 });
		// Load the data Points
		console.log("LOADING DATA***");

		pointsLoader.loadMany(urls.split(","));
	}, []);

	useLinkTag("/pdbe-molstar-3.0.0.css");
	useScript("/pdbe-molstar-component-3.0.0.js");

	return (
		<Router>
			<DataContext.Provider
				value={{
					data: data,
					pdbDataUrl: pdbDataUrl,
					loadingPoints: loadingPoints,
					setPdbDataUrl,
				}}
			>
				<Routes>
					<Route index element={<SplashPage />} />
					<Route
						path="explore"
						element={isMobile ? <MobileExplorerPage /> : <ExplorerPage />}
					/>
					<Route path="explore/detail/:mgnifyID" element={<DetailPage />} />
					<Route path="about" element={<AboutPage />} />
					<Route path="resources" element={<ResourcesPage />} />
					<Route path="resources/detail/:entry" element={<LightDetailPage />} />
					<Route path="resources/fold/result" element={<FoldSeqDetailPage />} />
					<Route
						path="resources/search_structure"
						element={<SearchStructurePage />}
					/>
					<Route
						path="resources/search_sequence"
						element={<SearchSequencePage />}
					/>
				</Routes>
			</DataContext.Provider>
		</Router>
	);
}

export default App;
