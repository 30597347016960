/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";

const useZoomIncrementer = (initialValue: number) => {
	const [value, setValue] = React.useState(initialValue);

	const increment = () => setValue(0.055);
	const decrement = () => setValue(24);
	const reset = () => setValue(initialValue);

	return { value, increment, decrement, reset };
};

export default useZoomIncrementer;
