/*
 * (c) Meta Platforms, Inc. and its affiliates.
 *
 */

import React from "react";

const useLinkTag = (url: string) => {
	React.useEffect(() => {
		const link = document.createElement("link");

		link.href = url;
		link.rel = "stylesheet";
		link.type = "text/css";

		document.body.appendChild(link);

		return () => {
			document.body.removeChild(link);
		};
	}, [url]);
};

export default useLinkTag;
