/*
 * (c) Meta Platforms, Inc. and its affiliates.
 *
 */

import React from "react";
import MainNavbar from "../navbars/MainNavbar";
import Footer from "../footers/Footer";
import Markdown from "react-markdown";
import AboutMD from "./AboutPage.md";
import { HeadingProps } from "react-markdown/lib/ast-to-react";

type TocItem = {
	level: number;
	id: string;
	title: string;
};

const AboutPage = () => {
	const [content, setContent] = React.useState<string>("");
	React.useEffect(() => {
		const fetchMarkdownFile = async () => {
			const response = await fetch(AboutMD);
			const data = await response.text();
			setContent(data);
		};

		fetchMarkdownFile();
		return () => {};
	}, []);

	const toc: TocItem[] = [];

	// In local `dev`, it renders twice due to React.StrictMode
	const customHeadingRenderer = ({
		children,
		...props
	}: React.PropsWithChildren<HeadingProps>) => {
		const level = Number(props.node.tagName.match(/h(\d)/)?.slice(1));
		if (level && children && typeof children[0] === "string") {
			const id = children[0].toLowerCase().replace(/[^a-z0-9]+/g, "_");
			toc.push({
				level,
				id,
				title: children[0],
			});
			return React.createElement(props.node.tagName, { id }, children);
		} else {
			return React.createElement(props.node.tagName, props, children);
		}
	};

	const TOC = () => {
		return (
			<ul
				className="menu menu-compact w-80 p-2 rounded-box font-bold hidden lg:block"
				style={{ backgroundColor: "rgba(17, 34, 102, 0.1)" }}
			>
				{toc.map(({ level, id, title }, idx) => (
					<li key={idx} className={`ml-${level === 2 ? "3" : "0"}`}>
						<a href={`#${id}`}>{title}</a>
					</li>
				))}
			</ul>
		);
	};

	return (
		<div className="bg-base-100 min-h-screen">
			<MainNavbar position="relative" variant="light" />
			<section className="w-full min-h-screen text-primary my-20">
				<div className="lg:flex lg:flex-row lg:px-40 max-w-[1520px] mx-auto">
					<div className="flex-auto w-full lg:w-64 mr-10 px-5">
						<br />
						<div className="prose prose-sm md:prose-base leading-normal">
							<Markdown
								children={content}
								components={{
									h1: customHeadingRenderer,
									h2: customHeadingRenderer,
								}}
							/>
						</div>
					</div>
					<div className="lg:flex-none">
						<div className="sticky top-10 mt-10">
							<TOC />
						</div>
					</div>
				</div>
			</section>
			<Footer position="relative" variant="light" />
		</div>
	);
};

export default AboutPage;
