/*
 * (c) Meta Platforms, Inc. and its affiliates.
 *
 */

export type CopyObject = {
	name: string;
	title: string;
	value_prop: string;
	placeholder: string;
};

export const resourcesCopy = [
	{
		name: "fold",
		title: "Fold Sequence",
		value_prop: "",
		placeholder: ">",
	},
	{
		name: "atlas_lookup",
		title: "Look up in the Atlas",
		value_prop: "",
		placeholder: "Enter a MGnifyID",
	},
	{
		name: "search_structure",
		title: "Search Structure",
		value_prop: "",
		placeholder: "",
	},
	{
		name: "search_sequence",
		title: "Search Sequence",
		value_prop: "",
		placeholder: "Enter protein sequence.",
	},
];
