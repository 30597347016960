/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import { useDebounce, useTitle } from "react-use";
import LoadingSpinner from "../../assets/custom_icons/loading_spinner.gif";
import { DataContext, DataPoint } from "../../context/DataContext";
import { useZoomIncrementer } from "../../hooks";
import ColorScaleCard from "../cards/ColorScaleCard";
import MetadataMenu from "../cards/MetadataMenu";
import ProteinImageCard from "../cards/ProteinImageCard";
import SeqCluster from "../charts/SeqCluster";
import Footer from "../footers/Footer";
import MainNavbar from "../navbars/MainNavbar";

const INITIAL_CAM_DISTANCE = 22;

const ExplorerPage = () => {
	useTitle("Explore - ESM Metagenomic Atlas");
	const { data } = React.useContext(DataContext);
	const zoomLevel = useZoomIncrementer(INITIAL_CAM_DISTANCE);

	const [showMetadataMenu, setShowMetadataMenu] =
		React.useState<boolean>(false);
	const [showName, setShowName] = React.useState<boolean>(false);
	const [pointData, setPointData] = React.useState<DataPoint | null>(null);
	const [hoveredData, setHoveredData] = React.useState<DataPoint | null>(null);
	const [sideBoxData, setSideBoxData] = React.useState<any>(undefined);

	/**
	 * Debounce the display of the protein image
	 * card to avoid excessive blinking.
	 */
	useDebounce(
		() => {
			setShowName(false);
		},
		1000,
		[pointData]
	);

	/**
	 * Triggers on point clicked to expose metadata on
	 * the metadata cars.
	 */
	const selectHandler = React.useCallback(
		(selected: any) => {
			if (selected.points.length === 1) {
				let metadata = data[selected.points[0]];
				setShowMetadataMenu(true);
				setSideBoxData(metadata);
			}
		},
		[data]
	);

	/**
	 * Triggers when on click outside a node area.
	 */
	const deselectHandler = React.useCallback(() => {
		if (pointData === null) {
			setShowMetadataMenu(false);
		}
	}, [pointData]);

	/**
	 * On pointhover, updates the mgnifyID to display and
	 * show the image card.
	 */
	const pointoverHandler = React.useCallback(
		(pointId: number) => {
			let metadata = data[pointId];
			setPointData(metadata);
			setHoveredData(metadata);
			setShowName(true);
		},
		[data]
	);

	const pointoutHandler = React.useCallback(() => {
		setPointData(null);
	}, []);

	return (
		<div className="relative page-container overflow-hidden">
			{!data.length && (
				<div className="bg-transparent fixed block top-1/2 right-0 bottom-0 left-0 z-50 w-[100%] h-[100%]">
					<img className="w-[60px] mx-auto" src={LoadingSpinner} alt="" />
				</div>
			)}

			<MainNavbar position="fixed" variant="dark" />
			{showName && hoveredData ? (
				<ProteinImageCard name={hoveredData.mgnifyID} />
			) : null}

			<MetadataMenu
				showMetadataMenu={showMetadataMenu}
				sideBoxData={sideBoxData}
				deselectHandler={deselectHandler}
			/>

			<SeqCluster
				data={data}
				pointData={pointData}
				pointoverHandler={pointoverHandler}
				pointoutHandler={pointoutHandler}
				selectHandler={selectHandler}
				zoomKey={zoomLevel.value}
			/>
			<ColorScaleCard
				onIncrement={zoomLevel.increment}
				onDecrement={zoomLevel.decrement}
			/>
			<Footer position="fixed" />
		</div>
	);
};

export default ExplorerPage;
