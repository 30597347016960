/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { CgArrowTopRight, CgMenu } from "react-icons/cg";
import { IoMdArrowDropdown } from "react-icons/io";

interface MainNavbarProps {
	position: "fixed" | "absolute" | "relative";
	variant?: "light" | "dark";
}

const MainNavbar = ({ position, variant }: MainNavbarProps) => {
	const { pathname, search } = useLocation();
	const sectionID = pathname.split("/")[1] || "home";
	const actionID = search.split("=")[1] || "";
	const hoverBg =
		variant === "light" ? "hover:bg-info/10" : "hover:bg-secondary";

	return (
		<div
			className={`${position} navbar min-h-0 p-1 z-50 ${
				variant === "light" ? "text-primary" : "text-white bg-gradient-navbar"
			}`}
		>
			<div className="navbar-start px-4">
				<Link to={"/"} className="font-OptimisticDisplay font-bold text-lg">
					<span className="hidden lg:inline whitespace-nowrap">
						ESM Metagenomic Atlas
					</span>
					<span className="inline lg:hidden">ESM</span>
				</Link>
			</div>
			<div className="navbar-end font-OptimisticDisplay font-bold text-sm">
				<div className="dropdown dropdown-end">
					<label tabIndex={0} className="btn btn-ghost lg:hidden text-lg">
						<CgMenu />
					</label>
					<ul
						tabIndex={0}
						className={`menu menu-compact dropdown-content mt-3 p-2 shadow rounded-box w-52 text-white ${
							variant === "dark" ? "bg-secondary" : "bg-primary"
						}`}
					>
						<li>
							<Link to={"/explore"}>Explore</Link>
						</li>
						<li tabIndex={0}>
							<button className="justify-between">Resources</button>
							<ul className="relative left-auto p-2">
								<li>
									<Link to={"/resources?action=fold"}>Fold Sequence</Link>
								</li>
								<li>
									<Link to={"/resources?action=atlas_lookup"}>
										Look up in the Atlas
									</Link>
								</li>
								<li>
									<Link to={"/resources?action=search_structure"}>
										Search Structure
									</Link>
								</li>
								<li>
									<Link to={"/resources?action=search_sequence"}>
										Search Sequence
									</Link>
								</li>
							</ul>
						</li>
						<li>
							<Link to={"/about"}>About</Link>
						</li>
						<li>
							<a
								href="https://ai.facebook.com/blog/protein-folding-esmfold-metagenomics/"
								target="_blank"
								rel="noreferrer"
							>
								Blog
							</a>
						</li>
						<li>
							<a
								href="https://github.com/facebookresearch/esm"
								target="_blank"
								rel="noreferrer"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									fill="currentColor"
									className="bi bi-github"
									viewBox="0 0 18 18"
								>
									<path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
								</svg>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div className="navbar-end hidden lg:flex text-sm font-OptimisticDisplay font-bold">
				<ul className="menu menu-horizontal menu-compact p-0 my-2">
					<li>
						<NavLink
							to={"/explore"}
							className={
								sectionID === "explore" ? "bg-white text-black" : hoverBg
							}
						>
							Explore
						</NavLink>
					</li>
					<li tabIndex={0} className="ml-1">
						<NavLink
							to="/resources?action=fold"
							className={`gap-1 ${
								sectionID === "resources" ? "bg-primary text-white" : hoverBg
							}`}
						>
							Resources <IoMdArrowDropdown />
						</NavLink>

						<ul className={`bg-secondary text-white border-t-4 ${ variant === 'light' ? 'border-base-100' : 'border-black'}`}>
							<li>
								<NavLink
									to={"/resources?action=fold"}
									className={`${
										actionID === "fold" ? "text-white/70" : ""
									} hover:text-accent bg-transparent`}
								>
									Fold Sequence
								</NavLink>
							</li>
							<li>
								<NavLink
									to={"/resources?action=atlas_lookup"}
									className={`${
										actionID === "atlas_lookup" ? "text-white/70" : ""
									} hover:text-accent bg-transparent`}
								>
                                    Look up in the Atlas
								</NavLink>
							</li>
							<li>
								<NavLink
									to={"/resources?action=search_structure"}
									className={`${
										actionID === "search_structure" ? "text-white/70" : ""
									} hover:text-accent bg-transparent`}
								>
									Search Structure
								</NavLink>
							</li>
							<li>
								<NavLink
									to={"/resources?action=search_sequence"}
									className={`${
										actionID === "search_sequence" ? "text-white/70" : ""
									} hover:text-accent bg-transparent`}
								>
									Search Sequence
								</NavLink>
							</li>
						</ul>
					</li>
					<li className="ml-1">
						<NavLink
							to={"/about"}
							className={
								sectionID === "about" ? "bg-primary text-white" : hoverBg
							}
						>
							About
						</NavLink>
					</li>
					<li className="ml-1">
						<a
							href="https://ai.facebook.com/blog/protein-folding-esmfold-metagenomics/"
							target="_blank"
							rel="noreferrer"
							className={`${hoverBg} gap-1`}
						>
							Blog <CgArrowTopRight />
						</a>
					</li>
					<li>
						<a
							href="https://github.com/facebookresearch/esm"
							target="_blank"
							rel="noreferrer"
							className={`${hoverBg} gap-1`}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								fill="currentColor"
								className="bi bi-github"
								viewBox="0 0 18 18"
							>
								<path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
							</svg>
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default MainNavbar;
