/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import MetaAILogo_A from "../../assets/branding/metaAI-regular-logo.png";
import MetaAILogo_B from "../../assets/branding/metaAI-white-logo.svg";

interface FooterProps {
	position: "fixed" | "absolute" | "relative";
	variant?: "light" | "dark";
}

function Footer({ position, variant }: FooterProps) {
	const addCopyFromLocation = () => {
		const query = window.location.search;
		const resultsPathname = window.location.pathname;
		const generateCopy = ({
			copy,
			linkCopy,
			link,
		}: {
			copy: string;
			linkCopy: string;
			link: string;
		}) => (
			<span
				className="inline font-medium rounded-sm"
				style={{ backgroundColor: "#EDEFF7" }}
			>
				{copy}{" "}
				<a
					className="font-black hover:underline decoration-2"
					style={{ color: "#112264" }}
					href={link}
					target={"_blank"}
					rel="noreferrer"
				>
					{linkCopy}
				</a>
			</span>
		);
		if (
			query === "?action=search_sequence" ||
			resultsPathname === "/resources/search_sequence"
		) {
			return generateCopy({
				copy: "Sequence Search Powered by",
				linkCopy: "MMseqs2",
				link: "https://github.com/soedinglab/MMseqs2",
			});
		} else if (
			query === "?action=search_structure" ||
			resultsPathname === "/resources/search_structure"
		) {
			return generateCopy({
				copy: "Structure Search Powered by",
				linkCopy: "Foldseek",
				link: "https://github.com/steineggerlab/foldseek",
			});
		} else if (
			query === "?action=fold" ||
			resultsPathname === "/resources/fold/result"
		) {
			return generateCopy({
				copy: "Predict protein structure with",
				linkCopy: "ESMFold",
				link: "https://github.com/facebookresearch/esm",
			});
		} else {
			return "";
		}
	};
	return (
		<footer
			className={`footer flex flex-row-reverse items-center justify-between ${position} bottom-2 mt-20 p-4 pl-6 text-base-content font-bold`}
		>
			<img
				className="mx-2 w-20 h-3 lg:w-auto text-left"
				src={variant !== "light" ? MetaAILogo_B : MetaAILogo_A}
				alt=""
			/>
			{addCopyFromLocation()}
		</footer>
	);
}

export default Footer;
