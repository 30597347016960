/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import { IoMdDownload } from "react-icons/io";
import { useParams } from "react-router-dom";
import { useCopyToClipboard, useTitle } from "react-use";
import LoadingSpinner from "../../assets/custom_icons/loading_spinner.gif";
import { useApi } from "../../hooks/useApi";
import { formatHeatMapData, HeatMapDataPoint } from "../../utils/Helpers";
import PredictedErrorCard from "../cards/PredictedErrorCard";
import ScalePlddtCard from "../cards/ScalePlddtCard";
import MolstarComponent from "../custom/Molstar";
import Footer from "../footers/Footer";
import ErrorAlert from "../miscellaneous/ErrorAlert";
import SharingContainer from "../miscellaneous/SharingContainer";
import MainNavbar from "../navbars/MainNavbar";
import { isMobile } from "react-device-detect";

const apiHost = process.env.REACT_APP_LAMBDA_API;

const DetailPage = () => {
	const { mgnifyID } = useParams();
	useTitle(`View ${mgnifyID} - ESM`);
	const [heatMapData, setHeatMapData] = React.useState<
		HeatMapDataPoint[] | undefined
	>(undefined);
	const [showError, setShowError] = React.useState<string | null>(null);
	const { isLoading, fetchConfindencePrediction } = useApi((err: any) => {
		err.response?.data?.status === "RATELIMIT"
			? setShowError("Rate Exceeded, please try again in a few.")
			: setShowError("There was an error.");
	});

	const [, copyToClipboard] = useCopyToClipboard();

	/**
	 * Invokes API to fetch heatmap data
	 */
	React.useEffect(() => {
		const fetchData = async () => {
			await fetchConfindencePrediction(mgnifyID as string, (data) => {
				if (data.pae.length < 250) {
					setHeatMapData(formatHeatMapData(data.pae));
				} else {
					setHeatMapData(undefined);
				}
			});
		};

		fetchData();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mgnifyID]);

	return (
		<div className="bg-gradient min-h-screen">
			<MainNavbar position="relative" />
			{showError && (
				<ErrorAlert
					position="absolute"
					showError={showError}
					setShowError={setShowError}
				/>
			)}
			<div className="container mx-auto xl:px-16 py-2">
				<div className="flex flex-row md:p-4 lg:p-10">
					<div className="basis-2/3 pl-6 lg:pr-5 lg:pl-10 py-1 mt-14 lg:mt-5">
						<div className="flex flex-row mt-4 items-center">
							<h2 className="mt-1 max-w-2xl text-md lg:text-2xl text-white font-bold">
								{mgnifyID}
							</h2>
							<button
								className="button btn-sm hover:opacity-50"
								onClick={() => copyToClipboard(mgnifyID || "")}
							>
								<svg
									width="18"
									height="20"
									viewBox="0 0 19 22"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M17 20H6V6H17M17 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6C19 5.46957 18.7893 4.96086 18.4142 4.58579C18.0391 4.21071 17.5304 4 17 4ZM14 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16H2V2H14V0Z"
										fill="white"
										fillOpacity="0.5"
									/>
								</svg>
							</button>
						</div>
					</div>

					<div className="basis-1/3"></div>
				</div>
			</div>

			<div className="container mx-auto xl:px-16 py-2 h-screen md:h-[600px]">
				<div className="flex flex-col lg:flex-row p-5 lg:p-10 h-full">
					<div className="basis-2/3 lg:pr-5 lg:pl-10 py-1 mt-24 lg:mt-5">
						<div className="relative h-full w-full max-w-full">
							<MolstarComponent
								pdbDataUrl={`${apiHost}/fetchPredictedStructure/${mgnifyID}.cif`}
								variant="dark"
								dataFormat="cif"
							/>
						</div>
					</div>
					<div className="basis-1/3 flex flex-col justify-between lg:pr-4 py-1">
						<div className="p-2">
							<p className="mx-3">Download</p>
							<div className="flex flex-row mt-4">
								<a
									className="btn btn-sm bg-purple mx-3 normal-case text-white"
									href={`${apiHost}/fetchPredictedStructure/${mgnifyID}.pdb`}
								>
									<IoMdDownload className="mr-2" size={"1.25em"} />
									PDB file
								</a>
								<a
									className="btn btn-sm bg-purple normal-case text-white"
									href={`${apiHost}/fetchSequence/${mgnifyID}.txt`}
								>
									<IoMdDownload className="mr-2" size={"1.25em"} />
									Sequence
								</a>
							</div>
							<ScalePlddtCard />
						</div>
						<div className="px-6 py-2">
							<SharingContainer
								title="ESM Metagenomic Atlas"
								text="An open atlas of 772 million predicted metagenomic protein structures"
								shareableImgFile={null}
							/>
						</div>
					</div>
				</div>
			</div>

			{heatMapData && (
				<div className="container mx-auto xl:px-16 py-2">
					<div className="md:flex md:flex-row p-5 lg:p-10 h-full">
						<div className="basis-2/3 h-full px-2 lg:px-10 py-1">
							{isLoading ? (
								<div className="flex flex-row justify-center text-center w-full mt-10">
									<img
										className="w-[60px] mx-auto"
										src={LoadingSpinner}
										alt=""
									/>
								</div>
							) : (
								<PredictedErrorCard data={heatMapData} />
							)}
						</div>
						<div className="basis-1/3 md:pr-4 py-1"></div>
					</div>
				</div>
			)}

			<section className="flex p-5"></section>
			<Footer position={isMobile ? "relative" : "absolute"} />
		</div>
	);
};

export default DetailPage;
