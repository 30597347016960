/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import * as fc from "d3fc";
import * as d3 from "d3";
import { RGBColor } from "d3";
import { DataPoint } from "../../context/DataContext";
import { useWindowSize } from "react-use";
import { colorsScale } from "../../utils/Helpers";

interface UMapClusterProps {
	data: DataPoint[];
	pointoverHandler: (pointId: number) => void;
}

const MAX_ZOOM = 1000;
const DELTA_RADIUS = 10;

const UMAPCluster = ({ data, pointoverHandler }: UMapClusterProps | any) => {
	const { width, height } = useWindowSize();

	/**
	 * Loads static data on page mount
	 */
	React.useLayoutEffect(() => {
		const ratio = width / height;
		const x = d3.scaleLinear().domain([-26, 26]);
		const y = d3.scaleLinear().domain([-30 / ratio, 30 / ratio]);
		const xScaleOriginal = x.copy();
		const yScaleOriginal = y.copy();

		// create a spatial index for rapidly finding the closest datapoint
		let quadtree = d3
			.quadtree()
			.x((d: any) => d.x)
			.y((d: any) => d.y)
			.addAll(data);

		const zoom: d3.ZoomBehavior<Element, unknown> = d3
			.zoom()
			.scaleExtent([0.5, MAX_ZOOM])
			.on("zoom", (event) => {
				// update the scales based on current zoom
				x.domain(event.transform.rescaleX(xScaleOriginal).domain());
				y.domain(event.transform.rescaleY(yScaleOriginal).domain());
				render();
			});

		const pointer = fc
			.pointer()
			.on("point", ([coord]: { x: number; y: number }[]) => {
				// find the closest datapoint to the pointer
				const nX = x.invert(coord?.x);
				const nY = y.invert(coord?.y);
				const radius = Math.abs(
					x.invert(coord?.x) - x.invert(coord?.x - DELTA_RADIUS)
				);
				const closestDatum = quadtree.find(nX, nY, radius);
				pointoverHandler(closestDatum);
				render();
			});

		const vals = [...Array(48)].map((_, i) => 0 + i * 0.0208);
		const color = d3
			.scaleLinear<string, number>()
			.domain(vals)
			.range(colorsScale);
		const fillColor = fc
			.webglFillColor()
			.value((d: any) => {
				const { r, g, b, opacity }: RGBColor = d3.rgb(color(d.score) as any);
				return [r / 255, g / 255, b / 255, opacity];
			})
			.data(data);

		const pointSeries = fc
			.seriesWebglPoint()
			.type(d3.symbolCircle)
			.xScale(x)
			.yScale(y)
			.crossValue((d: DataPoint) => d.x)
			.mainValue((d: DataPoint) => d.y)
			.size((d: any) => 5) // size of point.
			.defined(() => true)
			.equals(
				(previousData: DataPoint[], data: DataPoint) => previousData?.length > 0
			)
			.decorate((program: any) => {
				// Set the color of the points.
				fillColor(program);

				// Enable blending of transparent colors.
				const context = program.context();
				context.enable(context.BLEND);
				context.blendFunc(context.SRC_ALPHA, context.ONE_MINUS_SRC_ALPHA);
			});

		const informationOverlay = fc
			.seriesSvgPoint()
			.type(d3.symbolCircle)
			.xScale(x)
			.yScale(y)
			.crossValue((d: DataPoint, i: number) => d.x)
			.mainValue((d: DataPoint) => d.y)
			.defined((d: DataPoint) => d.mgnifyID! === "s")
			.size((d: any) => 10) //d.size
			.decorate((selection: DataPoint) => {});

		const chart = fc
			.chartCartesian(x, y)
			.svgPlotArea(informationOverlay)
			.webglPlotArea(
				fc
					.seriesWebglMulti()
					.series([pointSeries])
					.mapping((d: DataPoint) => d)
			)
			.decorate((selection) => {
				selection
					.enter()
					.select("d3fc-svg.plot-area")
					.on("measure.range", (event) => {
						xScaleOriginal.range([0, event.detail.width]);
						yScaleOriginal.range([event.detail.height, 0]);
					})
					.call(pointer)
					.call(zoom as d3.ZoomBehavior<Element, unknown> | any);
			});

		const render = () => {
			d3.select("#chart").datum(data).call(chart);
		};

		if (data.length) {
			render();
		}
	}, [data, height, width, pointoverHandler]);

	return (
		<React.Fragment>
			<div
				className="chart"
				id={"chart"}
				style={{ width: "100vw", height: "100vh" }}
			/>
		</React.Fragment>
	);
};

export default UMAPCluster;
