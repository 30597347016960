/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import { IoMdDownload } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import { useTitle } from "react-use";
import LoadingSpinner from "../../assets/custom_icons/loading_spinner.gif";
import { DataContext } from "../../context/DataContext";
import { useApi } from "../../hooks/useApi";
import ScalePlddtCard from "../cards/ScalePlddtCard";
import MolstarComponent from "../custom/Molstar";
import Footer from "../footers/Footer";
import ErrorAlert from "../miscellaneous/ErrorAlert";
import SharingContainer from "../miscellaneous/SharingContainer";
import MainNavbar from "../navbars/MainNavbar";
import { isMobile } from "react-device-detect";

const DetailPage = () => {
	useTitle("Fold Sequence - ESM");
	const { pdbDataUrl, setPdbDataUrl } = React.useContext(DataContext);
	const [currentQueryParameters] = useSearchParams();
	const [txtUrl, setTxtUrl] = React.useState("");
	const [showError, setShowError] = React.useState<string | null>(null);
	const [pdbData, setPdbData] = React.useState<string>("");
	const [fileName, setFileName] = React.useState<string>("");
	const { foldSequence, foldSequencePDB } = useApi((err: any) => {
		err.response?.data?.status === "RATELIMIT"
			? setShowError("Rate Exceeded, please try again in a few.")
			: setShowError("There was an error.");
	});
	React.useEffect(() => {
		let currentSequence = currentQueryParameters.get("sequence") || "";

		// Creates a file from the sequence string and convert it into url
		// to download on the client.
		const file = new Blob([currentSequence], { type: " text/plain" });
		const objectUrl = URL.createObjectURL(file);

		// Fold sequence api call to render pdb file inside the molstar visualizer.
		const fetchData = async () => {
			await foldSequence(currentSequence, async (data) => {
				let blob = new Blob([data], { type: "chemical/x-pdb" });
				const objectUrl = URL.createObjectURL(blob);
				setPdbDataUrl(objectUrl);
			});
			await foldSequencePDB(currentSequence, (data) => {
				let blob = new Blob([data], { type: "chemical/x-pdb" });
				const objectUrl = URL.createObjectURL(blob);
				setPdbData(objectUrl);
			});
		};

		setFileName(currentQueryParameters.get("fasta_header")!.substring(1));
		setTxtUrl(objectUrl);
		fetchData();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentQueryParameters]);

	return (
		<div className="bg-base-100 min-h-screen">
			<MainNavbar position="relative" variant="light" />
			{showError && (
				<ErrorAlert
					position="absolute"
					showError={showError}
					setShowError={setShowError}
				/>
			)}
			<div className="container mx-auto px-5 lg:px-16 py-2 mt-1 lg:mt-8">
				<div className="flex flex-row lg:p-5 lg:px-10">
					<div className="basis-2/3 lg:pr-5 lg:pl-10 py-1 lg:my-4 lg:mt-3">
						<div className="flex flex-row mt-4 items-center">
							<h2 className="mt-1 max-w-2xl text-md lg:text-2xl text-primary font-bold">
								{currentQueryParameters.get("fasta_header") ?? "untitled"}
							</h2>
						</div>
					</div>

					<div className="basis-1/3"></div>
				</div>
			</div>

			<div className="container mx-auto xl:px-16 py-2 h-screen md:h-[600px]">
				<div className="flex flex-col lg:flex-row p-5 lg:p-10 h-full">
					<div className="basis-2/3 lg:pr-5 lg:pl-10 py-1 mt-24 lg:mt-5">
						<div className="relative h-full w-full max-w-full">
							{pdbDataUrl !== "" ? (
								<MolstarComponent
									pdbDataUrl={pdbDataUrl}
									variant="light"
									dataFormat="cif"
								/>
							) : (
								<div className="flex flex-row justify-center text-center w-full mt-10">
									<img
										className="w-[60px] mx-auto"
										src={LoadingSpinner}
										alt=""
									/>
								</div>
							)}
						</div>
					</div>
					<div className="basis-1/3 flex flex-col justify-between lg:pr-4 py-1">
						<div className="p-2">
							<p className="mx-3 text-black">Download</p>
							<div className="flex flex-row mt-4">
								<a
									className="btn btn-sm bg-purple mx-3 normal-case text-white"
									href={pdbData as string}
									download={`${fileName || ""}.pdb`}
									target="_blank"
									rel="noreferrer"
								>
									<IoMdDownload className="mr-2" size={"1.25em"} />
									PDB file
								</a>
								<a
									className="btn btn-sm bg-purple normal-case text-white"
									href={txtUrl}
									download={`${
										currentQueryParameters.get("fasta_header") || ""
									}.txt`}
									target="_blank"
									rel="noreferrer"
								>
									<IoMdDownload className="mr-2" size={"1.25em"} />
									Sequence
								</a>
							</div>
							<ScalePlddtCard variant="light" />
						</div>
						<div className="px-6 py-2">
							<SharingContainer
								variant="light"
								title="ESM Metagenomic Atlas"
								text="An open atlas of 772 million predicted metagenomic protein structures"
								shareableImgFile={null}
							/>
						</div>
					</div>
				</div>
			</div>

			<section className="flex p-5"></section>
			<Footer position={isMobile ? "relative" : "absolute"} variant="light" />
		</div>
	);
};

export default DetailPage;
