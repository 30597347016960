/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import { useSearchParams } from "react-router-dom";
import * as d3 from "d3";
import { Stage, Layer } from "react-konva";
// import { Stage } from "konva/lib/Stage"
import { Layer as KonvaLayer } from "konva/lib/Layer";
import { Image as KonvaImage } from "konva/lib/shapes/Image";
import createScatterplot from "regl-scatterplot";
import { colorsScale } from "../../utils/Helpers";
//import ImgB from "../../assets/images/sm_proteinB.png";

import { useWindowSize, useDebounce } from "react-use";
import { DataContext, DataPoint } from "../../context/DataContext";

interface SeqClusterProps {
	data: DataPoint[];
	pointData: DataPoint | null;
	zoomKey: number;
	pointoverHandler: (pointId: number) => void;
	pointoutHandler: () => void;
	selectHandler: (points: number[] | undefined) => void;
}

type ProteinImgAsset = {
	id: string;
	x: number;
	y: number;
	src: string;
	point: number;
	mgnifyID?: string;
	isHovered: boolean;
	scaleImg: number;
};

const demo_url = process.env.REACT_APP_SITE_URL;
const image_folder = process.env.REACT_APP_S3_FOLDER_CLUSTER_IMAGES;
const INITIAL_CAM_DISTANCE = 22;

const MAX_ZOOM = 24;
const PROTEIN_ZOOM_START = MAX_ZOOM / 2;

const MIN_ZOOM_SCALE = 0.1;
const MAX_ZOOM_SCALE = 0.6;

// Calculate the scale for a protein image based on zoom
const calcScale = (zoom: number) => {
	// Linear algebra 101
	// calc gradient
	const gradient =
		(MAX_ZOOM_SCALE - MIN_ZOOM_SCALE) / (MAX_ZOOM - PROTEIN_ZOOM_START);
	// calc constant
	const b = MAX_ZOOM_SCALE - MAX_ZOOM * gradient;
	// return zoom value based on Linear scale
	return zoom * gradient + b;
};
const loadingImages = new Map<string, boolean>();

const SeqCluster = ({
	data,
	pointData,
	zoomKey,
	pointoverHandler,
	pointoutHandler,
	selectHandler,
}: SeqClusterProps) => {
	const canvasRef = React.useRef<HTMLCanvasElement>(null!);
	const layerRef = React.useRef(null);
	const windowSize = useWindowSize();
	const { loadingPoints } = React.useContext(DataContext);
	const [width, setWidth] = React.useState<number>(1);
	const [height, setHeight] = React.useState<number>(1);
	const [bgOpacity, setBgOpacity] = React.useState<number>(1);

	const [currentQueryParameters, setSearchParams] = useSearchParams();
	const newQueryParameters: URLSearchParams = new URLSearchParams();

	/**
	 * On page mount, initiate canvas dimensions.
	 */
	React.useEffect(() => {
		const canvas = canvasRef.current;
		const wrapper = canvas.parentElement?.getBoundingClientRect();
		setWidth(wrapper!.width);
		setHeight(wrapper!.height);
	}, []);

	/**
	 * Debounce window resizing to avoid
	 * expensive renderings.
	 */
	const [, cancelWindowDebouncing] = useDebounce(
		() => {
			setWidth(windowSize.width);
			setHeight(windowSize.height);
		},
		50,
		[windowSize]
	);

	function clearProteinImages() {
		var layer: KonvaLayer = layerRef.current!;
		layer.destroyChildren();
		layer.batchDraw();
	}

	function drawProteins(
		newSet: Map<string, ProteinImgAsset>,
		xScaleFn: d3.ScaleLinear<number, number, never> | any,
		yScaleFn: d3.ScaleLinear<number, number, never> | any
	) {
		if (newSet.size <= 0) {
			return;
		}
		var layer: KonvaLayer = layerRef.current!;

		// TODO Pass this in
		const firstValue = newSet.values().next().value;
		const zoom = 1 / firstValue.scaleImg;

		// Calculate how to scale images based on zoom level
		const scale = calcScale(zoom);

		var toRemove: KonvaImage[] = [];
		var updated = new Map<string, boolean>();

		layer.children?.forEach((image) => {
			if (!newSet.has(image.id())) {
				// Remove images
				toRemove.push(image as KonvaImage);
			} else {
				// Update imaged
				updated.set(image.id(), true);

				const entry = newSet.get(image.id());

				// Offset to align center of the image instead of the top left
				const xOffset = (image.width() * scale) / 2;
				const yOffset = (image.height() * scale) / 2;

				image.setAttrs({
					x: (entry?.x ?? 0) - xOffset,
					y: (entry?.y ?? 0) - yOffset,
					scaleX: scale,
					scaleY: scale,
				});
			}
		});

		// Remove images not in view.
		toRemove.forEach((image) => image.destroy());

		// Process newly shown images
		newSet.forEach((entry) => {
			// Track in progess image loads to avoid loading images twice.
			if (!updated.has(entry.mgnifyID!)) {
				if (!loadingImages.has(entry.src)) {
					loadingImages.set(entry.src, true);

					KonvaImage.fromURL(entry.src, function (image: any) {
						loadingImages.delete(entry.src);

						const xOffset = (image.width() * scale) / 2;
						const yOffset = (image.height() * scale) / 2;

						const point = data[entry.point];

						const scaledX = xScaleFn(point.x);
						const scaledY = yScaleFn(point.y);

						image.setAttrs({
							id: entry.mgnifyID,
							x: scaledX - xOffset,
							y: scaledY - yOffset,
							scaleX: scale,
							scaleY: scale,
						});
						layer?.add(image);
					});
				}
			}
		});
		layer.batchDraw();
	}

	/**
	 * sets the proteins images to show on zoom.
	 */
	const showProteinImages = React.useCallback(
		(
			pointsInView: number[],
			xScale: d3.ScaleLinear<number, number, never> | any,
			yScale: d3.ScaleLinear<number, number, never> | any,
			scale: number
		) => {
			let newSet = pointsInView.reduce((map, point, i) => {
				const mgnifyID = data[pointsInView[i]].mgnifyID;
				const hash = mgnifyID.substring(13, 16);

				const image_path = `${demo_url}/${image_folder}/${hash}/${mgnifyID}.png`;

				map.set(mgnifyID, {
					id: point.toString(),
					point: point,
					mgnifyID: mgnifyID,
					x: xScale(data[pointsInView[i]].x),
					y: yScale(data[pointsInView[i]].y),
					isHovered: false,
					//src: ImgB, //Test
					src: image_path,
					scaleImg: scale,
				});
				return map;
			}, new Map<string, ProteinImgAsset>());
			drawProteins(new Map<string, ProteinImgAsset>(newSet), xScale, yScale);
		},
		[data]
	);

	/**
	 * Draw scatterplot using Webgl.
	 * Updates the scatterplot on any change on data,
	 * only expected on component mount.
	 */

	React.useEffect(() => {
		let cam_position = currentQueryParameters.get("at") as string;
		const xScale = d3.scaleLinear().domain([-1, 1]).range([0, width]);
		const yScale = d3.scaleLinear().domain([-1, 1]).range([0, height]);
		const scatterplot = createScatterplot({
			canvas: canvasRef.current,
			width,
			height,
			xScale,
			yScale,
			pointSize: 10,
		});
		scatterplot.set({
			sizeBy: "value",
			colorBy: "value",
			cameraTarget: cam_position
				? [
						parseFloat(cam_position.split(",")[0]),
						parseFloat(cam_position.split(",")[1]),
				  ]
				: [1, 1],
			cameraDistance:
				zoomKey !== INITIAL_CAM_DISTANCE
					? zoomKey
					: cam_position
					? parseFloat(cam_position.split(",")[2])
					: INITIAL_CAM_DISTANCE,
			pointColor: colorsScale,
			pointColorHover: [1, 1, 1, 1],
			opacity: 0.85,
		});
		scatterplot.subscribe("pointOver", pointoverHandler, 100000);
		scatterplot.subscribe("pointOut", pointoutHandler, 100000);
		scatterplot.subscribe("select", selectHandler, 100000);

		const cam = scatterplot.get("camera");
		cam.setScaleBounds([0, MAX_ZOOM]);

		var timer: NodeJS.Timeout | null = null;

		scatterplot.subscribe(
			"view",
			({ xScale, yScale }) => {
				if (timer) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					newQueryParameters.set(
						"at",
						`${cam.target[0]},${cam.target[1]},${cam.distance[0]}`
					);
					setSearchParams(newQueryParameters, { replace: true });
				}, 100);

				const zoom = 1 / cam.distance[0];
				if (zoom > PROTEIN_ZOOM_START) {
					setBgOpacity(0.02);
					const pointsInView = scatterplot.get("pointsInView");
					showProteinImages(pointsInView, xScale, yScale, cam.distance[0]);
				} else {
					setBgOpacity(1);
					// hideProteinImages()
					clearProteinImages();
				}
			},
			100000
		);

		scatterplot.draw(data.map((d) => [d.x, d.y, d.id, d.score!]));

		return () => {
			scatterplot.destroy();
		};
	}, [
		width,
		height,
		data,
		zoomKey,
		cancelWindowDebouncing,
		pointoverHandler,
		pointoutHandler,
		selectHandler,
		showProteinImages,
	]);

	return (
		<React.Fragment>
			<div
				className={`${
					loadingPoints && "pointer-events-none"
				} absolute w-screen h-screen dark:bg-slate-100 text-white`}
			>
				<canvas ref={canvasRef} style={{ opacity: bgOpacity }}></canvas>

				<Stage
					width={width!}
					height={height!}
					style={{
						position: "absolute",
						right: 0,
						bottom: 0,
						left: 0,
						pointerEvents: "none",
					}}
				>
					<Layer listening ref={layerRef}>
						{/* IMAGES WILL BE ADDED HERE BY JS CODE */}
					</Layer>
				</Stage>
			</div>
		</React.Fragment>
	);
};

export default SeqCluster;
//
