/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import { Link } from "react-router-dom";
import Footer from "../footers/Footer";
import MainNavbar from "../navbars/MainNavbar";
import { CgArrowTopRight } from "react-icons/cg";
import { HiArrowRight } from "react-icons/hi";

function SplashPage() {
	return (
		<div className="page-container bg-splash">
			<MainNavbar position="fixed" variant="dark" />
			<div className="flex absolute left-0 lg:left-[50vw] bottom-0 right-0 justify-center pr-0 lg:pr-7">
				<div className="flex flex-col items-center lg:items-start w-full md:w-[400px]">
					<h2 className="text-4xl leading-10 font-OptimisticDisplay font-bold text-center lg:text-left mx-5 lg:mx-0">
						ESM Metagenomic Atlas{" "}
					</h2>
					<h4 className="text-lg leading-6 mt-3 mb-3 font-OptimisticDisplay text-center lg:text-left mx-10 lg:mx-0">
                        An open atlas of 772 million predicted metagenomic protein structures
					</h4>
					<Link to={"/explore"}>
						<button className="btn btn-secondary font-bold font-OptimisticDisplay text-xl text-white normal-case mt-5 btn-wide">
							Explore <HiArrowRight className="ml-2" />
						</button>
					</Link>
					<div className="flex flex-col items-start mt-[5rem] lg:mt-[20vh] mb-48 lg:mb-24 ">
						{[
							{ text: "Fold sequence", link: "/resources?action=fold" },
							{
								text: "Read blog post",
								link: "https://ai.facebook.com/blog/protein-folding-esmfold-metagenomics/",
							},
							{
								text: "Read research paper",
								link: "https://www.science.org/doi/abs/10.1126/science.ade2574",
							},
						].map((node, idx) => (
							<a
								href={node.link}
								key={idx}
								target="_blank"
								rel="noopener noreferrer"
							>
								<button className="btn btn-outline bg-black btn-ghost align-start justify-start btn-sm font-bold font-OptimisticDisplay md:text-md text-sm normal-case mt-2 text-white btn-wide">
									{node.text} <CgArrowTopRight className="ml-1" />
								</button>
							</a>
						))}
					</div>
				</div>
			</div>
			<Footer position="fixed" />
		</div>
	);
}

export default SplashPage;
