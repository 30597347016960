/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import Spinner from "../../assets/custom_icons/spinner_1.gif";

interface ProteinImageCardProps {
	name: string;
}

const demo_url = process.env.REACT_APP_SITE_URL;
const image_folder = process.env.REACT_APP_S3_FOLDER_CARDS_IMAGES;

function ProteinImageCard({ name }: ProteinImageCardProps) {
	const image_path = `${demo_url}/${image_folder}/${name.substring(
		13,
		16
	)}/${name}.png`;

	return (
		<div className="absolute top-16 right-5 z-40">
			<div className="relative menu menu-compact bg-black w-[320px] min-h-[280px] p-1 rounded-box border-solid border-2 ">
				<div className="max-w-xs p-1">
					<img
						className="rounded-lg w-4/5 m-auto h-full"
						src={image_path}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = Spinner;
							currentTarget.className = "spinner_img";
						}}
						alt=""
					/>
				</div>
				<div className="absolute bottom-0 px-3 py-5 sm:px-6 ">
					<p className="mt-1 max-w-2xl text-sm text-white">{name}</p>
				</div>
			</div>
		</div>
	);
}

export default ProteinImageCard;
