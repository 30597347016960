/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import { RiZoomInFill, RiZoomOutFill } from "react-icons/ri";

type ColorScaleCardProps = {
	onIncrement: () => void;
	onDecrement: () => void;
};

function ColorScaleCard({ onIncrement, onDecrement }: ColorScaleCardProps) {
	return (
		<div className="absolute bottom-3 left-3 flex flex-row z-[998]">
			<div className="hidden lg:flex bg-black/80 p-3 mr-2">
				<div
					className="h-full border-r-2 px-2 cursor-pointer"
					onClick={onIncrement}
				>
					<RiZoomInFill className="h-full hover:scale-110" size={"2em"} />
				</div>
				<div
					className="h-full border-r-2 px-2 cursor-pointer"
					onClick={onDecrement}
				>
					<RiZoomOutFill className="h-full hover:scale-110" size={"2em"} />
				</div>
				<div className="text-2xs px-3">
					<p>Drag to pan</p>
					<p>Scroll to zoom in/out</p>
					<p>Click to select a protein</p>
				</div>
			</div>

			<div className="bg-black/80 p-3">
				<div className="flex">
					<p className="text-white/60 text-xs">
						Exploring 1 million out of 772M proteins
					</p>
				</div>
				<div className="flex flex-row justify-between mb-2">
					<p className="font-bold text-xs lg:text-s text-white">Unknown</p>
					<p className="font-bold text-xs lg:text-s text-white">Known</p>
				</div>
				<div className="flex flex-row w-50 lg:w-80 ">
					<div
						className="p-1 w-full"
						style={{
							background:
								"linear-gradient(90deg, rgba(255,255,187,1) 0%, rgba(16,194,120,1) 50%, rgba(94,8,181,1) 100%)",
						}}
					></div>
				</div>
			</div>
		</div>
	);
}

export default ColorScaleCard;
