/*
 * (c) Meta Platforms, Inc. and its affiliates.
 *
 */

import { AxiosResponse } from "axios";
import React from "react";
import { FaSearch } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { useTitle } from "react-use";
import LoadingSpinner from "../../assets/custom_icons/loading_spinner.gif";
import { useApi } from "../../hooks/useApi";
import Footer from "../footers/Footer";
import ErrorAlert from "../miscellaneous/ErrorAlert";
import MainNavbar from "../navbars/MainNavbar";
import SeqResultsTable from "../tables/SeqResultsTable";

const SearchSequencePage = () => {
	useTitle("Search Sequence - ESM");
	const [currentQueryParameters, setSearchParams] = useSearchParams();
	const newQueryParameters: URLSearchParams = new URLSearchParams();
	const timerRef = React.useRef<NodeJS.Timer>(null!);

	const [data, setData] = React.useState(() => []);
	const [entry, setEntry] = React.useState<string>("");
	const [isRunning, setIsRunning] = React.useState<boolean>(false);
	const [showError, setShowError] = React.useState<string | null>(null);
	const { isLoading, reqTicketFromSequence, getTicketStatus, getSearchResult } =
		useApi((err: any) => {
			if (err.response) {
				handleApiError(err?.response);
			}
			err.response?.data?.status === "RATELIMIT"
				? setShowError("Rate Exceeded, please try again in a few.")
				: setShowError("There was an error.");
		});

	React.useEffect(() => {
		let ticket_id = currentQueryParameters.get("query_id") as string;
		let header = currentQueryParameters.get("fasta_header") as string;
		let seq = currentQueryParameters.get("sequence") as string;

		const fetchData = async () => {
			await getSearchResult(ticket_id, "sequence", (data) => {
				setData(data.results);
			});
		};

		fetchData();
		setEntry(`${header}\n${seq}`);

		return () => {
			clearInterval(timerRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleApiError = (res: AxiosResponse<unknown, any> | any) => {
		if (res?.data.status === "RATELIMIT") {
			alert("Too many requests");
		}
	};

	/**
	 * Updates the entry from text area input.
	 * @param e
	 */
	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setEntry(e.target.value);
	};

	/**
	 * Invokes api to make a POST request to generate a ticket
	 * on a new query to search a sequence against the MMseq2 database.
	 * Implements a chained http requests to get results when job status
	 * is COMPLETE only.
	 */
	const runSearchSequence = async () => {
		const inputLines = entry?.split(/\r?\n/);
		let fasta_header = inputLines.length > 1 ? inputLines[0] : ">unnamed";
		let sequence = inputLines.length > 1 ? inputLines[1] : inputLines[0];

		sequence = sequence?.toUpperCase();

		const ticketID = await reqTicketFromSequence(
			sequence, //"MPKIIEAIYENGVFKPLQKVDLKEGEKAKIVLESISDKTFGILKASETEIKKVLEEIDDFWGVC"
			fasta_header, //"TEST"
			(data) => {
				console.log(data);
			}
		);

		if (ticketID?.status === "PENDING" || ticketID?.status === "RUNNING") {
			setIsRunning(true);
			setData([]);
			timerRef.current = setInterval(
				async () =>
					await getTicketStatus(ticketID.id, "sequence", (data) => {
						if (data?.status === "COMPLETE") {
							newQueryParameters.set("query_id", ticketID.id);
							newQueryParameters.set("fasta_header", fasta_header);
							newQueryParameters.set("sequence", sequence);
							getSearchResult(ticketID.id, "sequence", (data) => {
								setData(data.results);
							});
							setSearchParams(newQueryParameters);
							setIsRunning(false);
							clearInterval(timerRef.current);
						}
					}),
				4000
			);
		} else if (ticketID?.status === "COMPLETE") {
			newQueryParameters.set("query_id", ticketID.id);
			newQueryParameters.set("fasta_header", fasta_header);
			newQueryParameters.set("sequence", sequence);
			await getSearchResult(ticketID.id, "sequence", (data) => {
				setData(data.results);
			});
			setSearchParams(newQueryParameters);
		} else {
			console.log("Error loading search result");
		}
	};

	return (
		<div className="bg-base-100 min-h-screen">
			<MainNavbar position="relative" variant="light" />
			{showError && (
				<ErrorAlert
					position="absolute"
					showError={showError}
					setShowError={setShowError}
				/>
			)}
			<div className="container flex justify-center lg:px-16 py-2 mt-12 mx-auto">
				<div className="relative flex rounded-md w-11/12 lg:w-4/6 mt-5">
					<span className="py-3 px-4 inline-flex flex-shrink-0 justify-center items-center rounded-l-md border border-transparent font-semibold bg-purple text-white hover:bg-purple/75 focus:z-10 focus:outline-none transition-all text-xs lg:text-sm btn-sm lg:btn-md">
						Search Sequence
					</span>

					<textarea
						id="search-input"
						name="search-input"
						className="textarea w-full bg-white disabled:bg-base-100/10 text-sm focus:outline-none dark:bg-gray-800 dark:border-gray-700 text-black input-sm h-[6rem] lg:input-md lg:h-[6rem] rounded-tl-sm"
						placeholder=">"
						rows={3}
						value={entry}
						onChange={handleChange}
					/>
					<div className="absolute top-0 lg:top-2 right-0 flex z-20 pr-1">
						<div
							className="cursor-pointer p-2 bg-white text-gray600"
							onClick={runSearchSequence}
						>
							<FaSearch size={"1.5em"} />
						</div>
					</div>
				</div>
			</div>
			{(isLoading || isRunning) && (
				<div className="flex flex-row justify-center text-center w-full mt-10">
					<img className="w-[60px] mx-auto" src={LoadingSpinner} alt="" />
				</div>
			)}
			<section className="px-0 lg:px-10">
				<SeqResultsTable data={data} />
			</section>
			<Footer position="relative" variant="light" />
		</div>
	);
};

export default SearchSequencePage;
