/*
 * (c) Meta Platforms, Inc. and its affiliates.
 *
 */

import { AxiosResponse } from "axios";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { FaSearch } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { useTitle } from "react-use";
import LoadingSpinner from "../../assets/custom_icons/loading_spinner.gif";
import { useApi } from "../../hooks/useApi";
import Footer from "../footers/Footer";
import ErrorAlert from "../miscellaneous/ErrorAlert";
import MainNavbar from "../navbars/MainNavbar";
import StructuresResultsTable from "../tables/StructuresResultsTable";

const SearchStructurePage = () => {
	useTitle("Search Structure - ESM");
	const [currentQueryParameters, setSearchParams] = useSearchParams();
	const timerRef = React.useRef<NodeJS.Timer>(null!);
	const newQueryParameters: URLSearchParams = new URLSearchParams();

	const [data, setData] = React.useState(() => []);
	const [entry, setEntry] = React.useState<string>("");
	const [pdbFormatText, setPdbFormatText] = React.useState<string>("");
	const [isRunning, setIsRunning] = React.useState<boolean>(false);

	const [textEncoded, setTextEncoded] = React.useState<string>();
	const [showError, setShowError] = React.useState<string | null>(null);
	const { isLoading, reqTicketFromPDBFile, getTicketStatus, getSearchResult } =
		useApi((err: any) => {
			if (err.response) {
				handleApiError(err?.response);
			}
			err.response?.data?.status === "RATELIMIT"
				? setShowError("Rate Exceeded, please try again in a few.")
				: setShowError("There was an error.");
		});

	React.useEffect(() => {
		let ticket_id = currentQueryParameters.get("query_id") as string;
		const fetchData = async () => {
			await getSearchResult(ticket_id, "structure", (data) => {
				setData(data.results);
			});
		};

		fetchData();
		return () => {
			clearInterval(timerRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Handles the logic to read and encoed file content to search against db.
	 * @param e
	 */
	const handleFileUpload = async (file: File) => {
		if (file) {
			const filename = file.name;
			setEntry(filename);

			const readFile = (file: File) => {
				return new Promise((acc, err) => {
					const reader = new FileReader();
					reader.onload = (event: ProgressEvent<FileReader>) => {
						acc(event.target?.result);
					};
					reader.onerror = (err: ProgressEvent<FileReader>) => {
						console.log(err);
					};
					reader.readAsText(file);
				});
			};

			const temp = await readFile(file);
			const encoded = encodeURIComponent(temp as string);

			setPdbFormatText(temp as string);
			setTextEncoded(encoded);
		}
	};

	const handleApiError = (res: AxiosResponse<unknown, any> | any) => {
		if (res?.data.status === "RATELIMIT") {
			alert("Too many requests");
		}
	};

	/**
	 * Invoke POST request on a new search with a .pdb file
	 * against foldseek database.
	 */
	const handleApiSearchCall = async () => {
		const ticketID = await reqTicketFromPDBFile(textEncoded!, (data) => {
			console.log(data);
		});

		if (ticketID?.status === "PENDING" || ticketID?.status === "RUNNING") {
			setIsRunning(true);
			setData([]);
			timerRef.current = setInterval(
				async () =>
					await getTicketStatus(ticketID.id, "structure", (data) => {
						if (data?.status === "COMPLETE") {
							newQueryParameters.set("query_id", ticketID.id);
							getSearchResult(ticketID.id, "structure", (data) => {
								setData(data.results);
							});
							setSearchParams(newQueryParameters);
							setIsRunning(false);
							clearInterval(timerRef.current);
						}
					}),
				4000
			);
		} else if (ticketID?.status === "COMPLETE") {
			newQueryParameters.set("query_id", ticketID.id);
			await getSearchResult(ticketID.id, "structure", (data) => {
				setData(data.results);
			});
			setSearchParams(newQueryParameters);
		} else {
			console.log("Error loading search result");
		}
	};

	return (
		<div className="bg-base-100 min-h-screen">
			<MainNavbar position="relative" variant="light" />
			{showError && (
				<ErrorAlert
					position="absolute"
					showError={showError}
					setShowError={setShowError}
				/>
			)}
			<div className="container flex justify-center lg:px-16 py-2 mt-12 mx-auto">
				<div className="relative flex rounded-md w-11/12 lg:w-3/6 mt-5 bg-white p-1">
					<span className="py-3 px-4 inline-flex flex-shrink-0 justify-center items-center rounded-l-md border border-transparent font-semibold bg-purple text-white hover:bg-purple/75 focus:z-10 focus:outline-none transition-all text-xs lg:text-sm btn-sm lg:btn-md">
						Search Structure
					</span>

					<input
						type="text"
						id="search-input"
						name="search-input"
						placeholder="Upload a .pdb file."
						className="py-3 px-4 pr-11 block w-full disabled:bg-white/90 border-gray-200 rounded-r-md text-sm focus:z-10 focus:outline-none dark:bg-gray-800 dark:border-gray-700 text-black input-sm lg:input-md"
						disabled
						readOnly
						value={entry}
					/>
					<div className="absolute top-2 lg:top-2 left-[9rem] lg:left-[10rem] flex lg:w-[calc(100%_-_13rem)] h-6 lg:h-10 z-10 pr-1 text-black">
						<FileUploader
							handleChange={handleFileUpload}
							name="file"
							types={["pdb"]}
							hoverTitle=""
							classes="lg:w-full"
							children={
								<div
									className={`w-full h-full flex border-gray-700 rounded items-center bg-white px-4 text-xs lg:text-sm cursor-pointer ${
										pdbFormatText === "" &&
										"outline-dashed outline-2 outline-offset-2"
									}`}
								>
									{entry
										? entry
										: "Click to select or drag a file here to upload"}
								</div>
							}
						/>
					</div>
					<div className="absolute top-1 lg:top-2 right-0 flex z-20 pr-1">
						<div
							className="cursor-pointer p-2 bg-white text-gray600"
							onClick={handleApiSearchCall}
						>
							<FaSearch className="h-[16px] lg:h-[1.25em] w-auto" />
						</div>
					</div>
				</div>
			</div>

			<section className="w-full min-h-[20px]">
				{pdbFormatText && (
					<section className="flex justify-center w-11/12 lg:w-2/5 mx-auto mt-2">
						<textarea
							id="display-input_"
							name="display-input_"
							className={`textarea textarea-bordered w-full bg-gray/20 disabled:bg-base-100/10 border-gray-200 rounded-r-md text-sm focus:z-10 dark:bg-gray-800 dark:border-gray-700 text-black input-sm h-[20vh] lg:input-md lg:h-[15vh] ${
								pdbFormatText === "" &&
								"outline-dashed outline-2 outline-offset-2"
							}`}
							placeholder="Select or drop a file to upload."
							rows={3}
							readOnly
							value={pdbFormatText}
						/>
					</section>
				)}
			</section>
			{(isLoading || isRunning) && (
				<div className="flex flex-row justify-center text-center w-full mt-10">
					<img className="w-[60px] mx-auto" src={LoadingSpinner} alt="" />
				</div>
			)}
			<section className="px-0 lg:px-10">
				<StructuresResultsTable data={data} />
			</section>
			<Footer position="relative" variant="light" />
		</div>
	);
};

export default SearchStructurePage;
