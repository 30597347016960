/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";

interface ErrorAlertProps {
    position: "absolute" | "relative" | "fixed";
    showError: string | null;
    setShowError: (arg: string | null) => void;
}

const ErrorAlert = ({ position, showError, setShowError }: ErrorAlertProps) => {
    return (
        <div
            className={`${position} top-10 container flex justify-center py-2 mt-2`}
        >
            <div className="alert alert-error shadow-lg lg:w-1/2">
                <div onClick={() => setShowError(null)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current flex-shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <span>Error! {showError || "There was an error."}</span>
                </div>
            </div>
        </div>
    );
};

export default ErrorAlert;
