/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCopyToClipboard, useTitle } from "react-use";
import LoadingSpinner from "../../assets/custom_icons/loading_spinner.gif";
import { useApi } from "../../hooks/useApi";
import { formatHeatMapData, HeatMapDataPoint } from "../../utils/Helpers";
import PredictedErrorCard from "../cards/PredictedErrorCard";
import ScalePlddtCard from "../cards/ScalePlddtCard";
import MolstarComponent from "../custom/Molstar";
import Footer from "../footers/Footer";
import ErrorAlert from "../miscellaneous/ErrorAlert";
import SharingContainer from "../miscellaneous/SharingContainer";
import MainNavbar from "../navbars/MainNavbar";

const apiHost = process.env.REACT_APP_LAMBDA_API;

const LightDetailPage = () => {
	const { entry } = useParams();
	useTitle(`View ${entry} - ESM`);

	const navigate = useNavigate();
	const [heatMapData, setHeatMapData] = React.useState<
		HeatMapDataPoint[] | undefined
	>(undefined);
	const [showError, setShowError] = React.useState<string | null>(null);
	const [newEntry, setNewEntry] = React.useState<string | undefined>(entry);
	const { isLoading, fetchConfindencePrediction } = useApi((err: any) => {
		err.response?.data?.status === "RATELIMIT"
			? setShowError("Rate Exceeded, please try again in a few.")
			: setShowError("There was an error.");
	});

	const [, copyToClipboard] = useCopyToClipboard();

	/**
	 * Invokes API to fetch heatmap data
	 */
	React.useLayoutEffect(() => {
		const fetchData = async () => {
			await fetchConfindencePrediction(entry as string, (data) => {
				if (data.pae.length < 250) {
					setHeatMapData(formatHeatMapData(data.pae));
				} else {
					setHeatMapData(undefined);
				}
			});
		};

		fetchData();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entry]);

	/**
	 * Handles text input/text area on search.
	 * @param e
	 */
	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setNewEntry(e.target.value.toUpperCase());
	};

	/**
	 * Handles user's click on search button.
	 * @returns
	 */
	const handleOnClick = () => {
		if (!newEntry) return;
		navigate(`/resources/detail/${newEntry}`);
		window.location.reload();
	};

	/**
	 * Triggres when key Enter is hit, only on MgnifyID (single line input).
	 * @param e
	 */
	const handleKeyDown = (
		e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (e.key === "Enter" && newEntry) {
			navigate(`/resources/detail/${newEntry}`);
			window.location.reload();
		}
	};

	return (
		<div className="bg-base-100 min-h-screen">
			<MainNavbar position="relative" variant="light" />
			{showError && (
				<ErrorAlert
					position="absolute"
					showError={showError}
					setShowError={setShowError}
				/>
			)}
			<div className="container flex justify-center lg:px-16 py-2 mt-12 mx-auto">
				<div className="relative flex rounded-md shadow-sm w-11/12 lg:w-3/6 mt-5">
					<span className="py-3 px-4 inline-flex flex-shrink-0 justify-center items-center rounded-l-md border border-transparent font-semibold bg-purple text-white hover:bg-purple/80 focus:z-10 focus:outline-none transition-all text-sm btn-sm lg:btn-md">
						MgnifyID
					</span>

					<input
						type="text"
						name="search-input"
						placeholder="Search for a MgnifyID..."
						className="py-3 px-4 pr-11 block w-full disabled:bg-neutral/10 border-gray-200 shadow-sm rounded-r-md text-sm focus:z-10 focus:outline-none dark:bg-gray-800 dark:border-gray-700 text-black input-sm lg:input-md"
						value={newEntry || ""}
						onChange={handleChange}
						onKeyDown={handleKeyDown}
					/>
					<div className="absolute inset-y-0 right-0 flex items-center z-20 pr-4">
						<div className="cursor-pointer p-2" onClick={handleOnClick}>
							<svg
								className="h-4 w-4"
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="blue"
								viewBox="0 0 16 16"
							>
								<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
							</svg>
						</div>
					</div>
				</div>
			</div>

			<div className="container mx-auto px-5 xl:px-16 py-2">
				<div className="flex flex-row lg:p-5 lg:px-10">
					<div className="basis-2/3 lg:pr-5 lg:pl-10 py-1 lg:my-4 lg:mt-3">
						<div className="flex flex-row mt-4 items-center">
							<h2 className="mt-1 max-w-2xl text-md lg:text-2xl text-primary font-bold">
								{entry}
							</h2>
							<button
								className="button btn-sm hover:opacity-50"
								onClick={() => copyToClipboard(entry || "")}
							>
								<svg
									width="18"
									height="20"
									viewBox="0 0 19 22"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M17 20H6V6H17M17 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6C19 5.46957 18.7893 4.96086 18.4142 4.58579C18.0391 4.21071 17.5304 4 17 4ZM14 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16H2V2H14V0Z"
										fill="black"
										fillOpacity="0.5"
									/>
								</svg>
							</button>
						</div>
					</div>

					<div className="basis-1/3"></div>
				</div>
			</div>

			<div className="container mx-auto xl:px-16 py-2 h-screen md:h-[600px]">
				<div className="flex flex-col lg:flex-row p-5 lg:p-10 h-full">
					<div className="basis-2/3 lg:pr-5 lg:pl-10 py-1 mt-24 lg:mt-5">
						<div className="relative h-full w-full max-w-full">
							<MolstarComponent
								pdbDataUrl={`${apiHost}/fetchPredictedStructure/${entry}.cif`}
								variant="light"
								dataFormat="cif"
							/>
						</div>
					</div>
					<div className="basis-1/3 flex flex-col justify-between lg:pr-4 py-1">
						<div className="p-2">
							<p className="mx-3 text-black">Download</p>
							<div className="flex flex-row mt-4">
								<a
									className="btn btn-sm bg-purple mx-3 normal-case text-white"
									href={`${apiHost}/fetchPredictedStructure/${entry}.pdb`}
								>
									<svg
										className="mr-2"
										width="12"
										height="14"
										viewBox="0 0 12 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M0 14H11.5294V12.3529H0M11.5294 4.94118H8.23529V0H3.29412V4.94118H0L5.76471 10.7059L11.5294 4.94118Z"
											fill="white"
										/>
									</svg>
									PDB file
								</a>
								<a
									className="btn btn-sm bg-purple normal-case text-white"
									href={`${apiHost}/fetchSequence/${entry}.fasta`}
								>
									<svg
										className="mr-2"
										width="12"
										height="14"
										viewBox="0 0 12 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M0 14H11.5294V12.3529H0M11.5294 4.94118H8.23529V0H3.29412V4.94118H0L5.76471 10.7059L11.5294 4.94118Z"
											fill="white"
										/>
									</svg>
									Sequence
								</a>
							</div>
							<ScalePlddtCard variant="light" />
						</div>
						<div className="px-6 py-2">
							<SharingContainer
								variant="light"
								title="ESM Metagenomic Atlas"
								text="An open atlas of 772 million metagenomic predicted protein structures"
								shareableImgFile={null}
							/>
						</div>
					</div>
				</div>
			</div>
			{heatMapData && (
				<div className="container mx-auto xl:px-16 py-2">
					<div className="md:flex md:flex-row p-2 lg:p-10 h-full">
						<div className="basis-2/3 h-full px-2 lg:px-10 py-1">
							{isLoading ? (
								<div className="flex flex-row justify-center text-center w-full mt-10">
									<img
										className="w-[60px] mx-auto"
										src={LoadingSpinner}
										alt=""
									/>
								</div>
							) : (
								<PredictedErrorCard data={heatMapData} variant="light" />
							)}
						</div>
						<div className="basis-1/3 md:pr-4 py-1"></div>
					</div>
				</div>
			)}

			<section className="flex p-5"></section>
			<Footer position="relative" variant="light" />
		</div>
	);
};

export default LightDetailPage;
