/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import * as d3 from "d3";
import { HeatMapDataPoint } from "../../utils/Helpers";
import { useMedia } from "react-use";

interface HeatMapProps {
	data: HeatMapDataPoint[];
	variant?: "light" | "dark";
}

const HeatMap = ({ data, variant }: HeatMapProps) => {
	const isWide = useMedia("(min-width: 480px)");
	const color_text = variant === "light" ? "black" : "white";

	React.useLayoutEffect(() => {
		const width = isWide ? 400 : 200;
		const height = isWide ? 400 : 200,
			margins = isWide
				? { top: 80, right: 80, bottom: 80, left: 80 }
				: { top: 60, right: 60, bottom: 60, left: 60 };

		if (data?.length) {
			const yScale = d3
				.scaleLinear()
				.range([height, 0])
				.domain([Math.sqrt(data?.length), 0]);

			const xScale = d3
				.scaleLinear()
				.range([0, width])
				.domain([0, Math.sqrt(data?.length)]);

			//Setting chart width and adjusting for margins
			const chart = d3
				.select("#chartd3")
				.attr("width", width + margins.right + margins.left)
				.attr("height", height + margins.top + margins.bottom)
				.append("g")
				.attr(
					"transform",
					"translate(" + margins.left + "," + margins.top + ")"
				);

			const tooltip = d3
				.select(".container_d3")
				.append("div")
				.attr("class", "tooltip_hmap")
				.style("fill", "black")
				.html("Tooltip");

			const barWidth = width / Math.sqrt(data?.length);
			const barHeight = height / Math.sqrt(data?.length);

			const color = d3
				.scaleLinear<string, number>()
				.domain([0, 30])
				.range(["green", "white"]);

			//Append heatmap bars, styles, and mouse events
			chart
				.selectAll("g")
				.data(data)
				.enter()
				.append("g")
				.append("rect")
				.attr("x", (d: HeatMapDataPoint) => {
					return (d.x - 1) * barWidth;
				})
				.attr("y", (d: HeatMapDataPoint) => {
					return (d.y - 1) * barHeight;
				})
				.style("fill", function (d: HeatMapDataPoint) {
					return color(d.value);
				})
				.attr("width", barWidth)
				.attr("height", barHeight)
				.on("mouseover", (event, d: HeatMapDataPoint) => {
					tooltip
						.style("opacity", 0.9)
						.style("left", event.layerX - 5 + "px")
						.style("top", event.layerY + 2 + "px")
						.html(
							"pae: " + d.value + "<br/> [ x: " + d.x + ", y: " + d.y + " ]"
						)
						.style("fill", "black");
				})
				.on("mouseout", () => {
					tooltip.style("opacity", 0).style("left", "0px");
				});

			//Append x axis
			chart
				.append("g")
				.attr("transform", "translate(0," + height + ")")
				.call(d3.axisBottom(xScale).tickFormat(d3.format(".4")));

			//Append y axis
			chart
				.append("g")
				.attr("transform", "translate(0,-" + barHeight / 2 + ")")
				.call(d3.axisLeft(yScale));

			//Append y axis label
			chart
				.append("text")
				.attr("transform", "translate(-40," + height / 2 + ") rotate(-90)")
				.style("text-anchor", "middle")
				.style("fill", color_text)
				.text("Align residue");

			//Append x axis label
			chart
				.append("text")
				.attr("transform", "translate(" + width / 2 + "," + (height + 40) + ")")
				.style("text-anchor", "middle")
				.style("fill", color_text)
				.text("Scored residue");
		}

		return () => {};
	}, [data, color_text, isWide]);

	return (
		<div
			className="container_d3"
			style={{
				width: isWide ? "560px" : "320px",
				height: isWide ? "560px" : "320px",
			}}
		>
			<svg id="chartd3"></svg>
		</div>
	);
};

export default HeatMap;
