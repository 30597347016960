/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";

export interface DataPoint {
	id: number;
	x: number;
	y: number;
	mgnifyID: string;
	score?: number;
}

interface DataContextType {
	data: DataPoint[];
	pdbDataUrl: URL | string;
	loadingPoints: boolean;
	setPdbDataUrl: React.Dispatch<React.SetStateAction<string | URL>>;
}

export const DataContext = React.createContext<DataContextType>({
	data: [],
	pdbDataUrl: "",
	loadingPoints: false,
	setPdbDataUrl: () => {},
});
