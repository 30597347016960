/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";

interface ScalePlddtCardProps {
	variant?: "light" | "dark";
}

const ScalePlddtCard = ({ variant }: ScalePlddtCardProps) => {
	return (
		<div className="p-2 overflow-auto mt-5 text-sm">
			<dl
				className={`py-1 ${
					variant === "light" ? "text-primary" : "text-white"
				}`}
			>
				<div className="px-2 grid grid-cols-1 gap-1">
					<div className="flex flex-row mt-2">
						The predicted structure is colored by local prediction confidence
						(pLDDT) per amino acid location. Blue indicates confident
						predictions (pLDDT {">"} 0.9), while red indicates low confidence
						(pLDDT {"<"} 0.5).
					</div>
				</div>
			</dl>
		</div>
	);
};

export default ScalePlddtCard;
