/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";

declare global {
	namespace JSX {
		interface IntrinsicElements {
			"pdbe-molstar": React.DetailedHTMLProps<
				React.HTMLAttributes<HTMLElement>,
				HTMLElement
			>;
		}
	}
}

interface MolstarComponentProps {
	pdbDataUrl: string | URL;
	variant?: "light" | "dark";
	dataFormat: "cif" | "pdb";
}

const MolstarComponent = ({
	pdbDataUrl,
	variant,
	dataFormat,
}: MolstarComponentProps) => {
	return (
		<pdbe-molstar
			//className="mol_ msp-plugin msp-sequence-wrapper-non-empty"
			id="pdbeMolstarComponent"
			custom-data-url={pdbDataUrl}
			custom-data-format={dataFormat}
			alphafold-view="true"
			bg-color-r={variant === "light" ? "255" : ""}
			bg-color-g={variant === "light" ? "255" : ""}
			bg-color-b={variant === "light" ? "255" : ""}
			hide-selection-icon="true"
			hide-animation-icon="true"
		></pdbe-molstar>
	);
};

export default MolstarComponent;
