/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import { AxiosError } from "axios";
import React from "react";
import { BsArrowDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import ProteinImg from "../../assets/images/1g3qA-mol.png";
import { DataPoint } from "../../context/DataContext";
import { useOutsideClick } from "../../hooks";
import { useApi } from "../../hooks/useApi";
import ErrorAlert from "../miscellaneous/ErrorAlert";
import SharingContainer from "../miscellaneous/SharingContainer";

interface MetadataMenuProps {
	sideBoxData: DataPoint;
	showMetadataMenu: boolean;
	deselectHandler: () => void;
}

interface DataPointContent {
	PDB: { [key: string]: string | number };
	UniRef90: { [key: string]: string | number };
	standalone_metrics: { [key: string]: string | number };
}

const demo_url = process.env.REACT_APP_SITE_URL;
const image_folder = process.env.REACT_APP_S3_FOLDER_CARDS_IMAGES;

const MetadataMenu = ({
	sideBoxData,
	showMetadataMenu,
	deselectHandler,
}: MetadataMenuProps) => {
	const [metadata, setMetadata] = React.useState<DataPointContent>();
	const [shareableImgFile, setShareableImgFile] = React.useState<File | null>(
		null
	);
	const [showError, setShowError] = React.useState<string | null>(null);
	const { isLoading, fetchMetrics } = useApi((err: any) => {
		err.response?.data?.status === "RATELIMIT"
			? setShowError("Rate Exceeded, please try again in a few.")
			: setShowError("There was an error.");
	});

	const image_path = `${demo_url}/${image_folder}/${sideBoxData?.mgnifyID.substring(
		13,
		16
	)}/${sideBoxData?.mgnifyID}.png`;

	/**
	 * Invokes API to fetch data point's metadata
	 */
	React.useEffect(() => {
		const fetchData = async () => {
			await fetchMetrics(sideBoxData?.mgnifyID, (data: DataPointContent) => {
				setMetadata(data);
			});
		};

		// Make a file from the image url, to use in the sharing component.
		const imgUrlToBlob = async () => {
			const image = await fetch(image_path);
			const blob = await image.blob();
			const file = new File([blob], "esm_protein.png", { type: "image/png" });
			setShareableImgFile(file);
		};

		if (sideBoxData) {
			fetchData();
			imgUrlToBlob();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sideBoxData?.mgnifyID]);

	const controlsRef = useOutsideClick(deselectHandler);

	return (
		<div
			className={`${
				showMetadataMenu ? "block" : "hidden"
			} absolute top-16 right-5 z-[999] w-60 lg:w-[380px] max-w-[380px] text-2xs`}
			ref={controlsRef}
		>
			{showError && (
				<ErrorAlert
					position="absolute"
					showError={showError}
					setShowError={setShowError}
				/>
			)}
			<div className="menu menu-compact bg-black p-2 rounded-box border-solid border-2">
				<div className="px-4 py-5 sm:px-6">
					<div className="relative max-w-xs p-1 min-h-[140px] lg:min-h-[200px]">
						<img
							className="rounded-lg w-full m-auto"
							src={image_path || ProteinImg}
							alt=""
						/>
					</div>
				</div>
				<div className="py-1 px-1 lg:px-5 mb-2">
					<div className="flex flex-row justify-between items-center">
						<div className="px-1 text-2xs lg:text-sm">
							<h3 className="font-bold text-gray/50">MGnify ID</h3>
							<h2 className="text-white font-bold">
								{sideBoxData && sideBoxData.mgnifyID!}
							</h2>
						</div>
						<Link
							to={`detail/${sideBoxData && sideBoxData.mgnifyID}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<button className="btn bg-purple btn-xs normal-case mr-auto text-white text-[0.7em] lg:text-sm">
								View Structure
							</button>
						</Link>
					</div>
				</div>
				<div className="border-t border-black py-1 bg-[#160449]">
					<dl className="py-3 overflow-auto max-h-[30vh] lg:max-h-[36vh] lg:h-full">
						<div className="">

							<div className="px-2 lg:px-6 py-0 grid grid-cols-2 gap-4">
								<dt className="text-2xs lg:text-xs font-medium text-gray/70">
									pTM
								</dt>
								<dd className="mt-1 text-2xs lg:text-xs sm:mt-0 text-left">
									{metadata?.standalone_metrics["pTM"]}
								</dd>
							</div>
							<div className="px-2 lg:px-6 py-0 grid grid-cols-2 gap-4">
								<dt className="text-2xs lg:text-xs font-medium text-gray/70">
									Mean pLDDT
								</dt>
								<dd className="mt-1 text-2xs lg:text-xs sm:mt-0 text-left">
									{metadata?.standalone_metrics["Mean pLDDT"]}
								</dd>
							</div>
							<div className="px-2 lg:px-6 py-0 grid grid-cols-2 gap-4">
								<dt className="text-2xs lg:text-xs font-medium text-gray/70">
									Sequence length
								</dt>
								<dd className="mt-1 text-2xs lg:text-xs sm:mt-0 text-left">
									{metadata?.standalone_metrics["Sequence length"]}
								</dd>
							</div>

							<div className="px-2 lg:px-6 py-2">
								<hr className="border-accent" />
							</div>

							<div className="px-2 lg:px-6 py-1 grid grid-cols-2 gap-4">
								<dt className="text-2xs lg:text-xs font-bold text-accent">
									PDB
								</dt>
								<dd className="mt-1 text-2xs lg:text-xs sm:mt-0 text-left"></dd>
							</div>

							<div className="px-2 lg:px-6 py-0 grid grid-cols-2 gap-4">
								<dt className="text-2xs lg:text-xs font-medium text-gray/70">
									Nearest Neighbor (NN)
								</dt>
								<dd className="mt-1 text-2xs lg:text-xs sm:mt-0 text-left">
									<a
										className="link hover:text-accent"
										target="_blank"
										rel="noopener noreferrer"
										href={metadata?.PDB["url"] as string}
									>
										{metadata?.PDB["Nearest Neighbor (NN)"]}
									</a>
								</dd>
							</div>
							<div className="px-2 lg:px-6 py-0 grid grid-cols-2 gap-4">
								<dt className="text-2xs lg:text-xs font-medium text-gray/70">
									TM-score to NN
								</dt>
								<dd className="mt-1 text-2xs lg:text-xs sm:mt-0 text-left">
									{metadata?.PDB["TM-score to NN"]}
								</dd>
							</div>
							<div className="px-2 lg:px-6 py-0 grid grid-cols-2 gap-4">
								<dt className="text-2xs lg:text-xs font-medium text-gray/70">
									Number of Foldseek hits
								</dt>
								<dd className="mt-1 text-2xs lg:text-xs sm:mt-0 text-left">
									{metadata?.PDB["Number of Foldseek hits"]}
								</dd>
							</div>

							<div className="px-2 lg:px-6 py-2">
								<hr className="border-accent" />
							</div>

							<div className="px-2 lg:px-6 py-1 grid grid-cols-2 gap-4">
								<dt className="text-2xs lg:text-xs font-bold text-accent">
									UniRef90
								</dt>
								<dd className="mt-1 text-2xs lg:text-xs sm:mt-0 text-left"></dd>
							</div>
							<div className="px-2 lg:px-6 py-0 grid grid-cols-2 gap-4">
								<dt className="text-2xs lg:text-xs font-medium text-gray/70">
									Nearest Neighbor (NN)
								</dt>
								<dd className="mt-1 text-2xs lg:text-xs sm:mt-0 text-left">
									<a
										className="link hover:text-accent"
										target="_blank"
										rel="noopener noreferrer"
										href={metadata?.UniRef90["url"] as string}
									>
										{metadata?.UniRef90["Nearest Neighbor (NN)"]}
									</a>
								</dd>
							</div>

							<div className="px-2 lg:px-6 py-0 grid grid-cols-2 gap-4">
								<dt className="text-2xs lg:text-xs font-medium text-gray/70">
									E-value of NN
								</dt>
								<dd className="mt-1 text-2xs lg:text-xs sm:mt-0 text-left">
									{metadata?.UniRef90["E-value of NN"]}
								</dd>
							</div>
							<div className="px-2 lg:px-6 py-0 grid grid-cols-2 gap-4">
								<dt className="text-2xs lg:text-xs font-medium text-gray/70">
									Max sequence identity
								</dt>
								<dd className="mt-1 text-2xs lg:text-xs sm:mt-0 text-left">
									{metadata?.UniRef90["Max sequence identity"]}
								</dd>
							</div>
							<div className="px-2 lg:px-6 py-0 grid grid-cols-2 gap-4">
								<dt className="text-2xs lg:text-xs font-medium text-gray/70">
									Number of sequence hits
								</dt>
								<dd className="mt-1 text-2xs lg:text-xs sm:mt-0 text-left">
									{metadata?.UniRef90["Number of sequence hits"]}
								</dd>
							</div>



							<div className="hidden md:block mt-4 px-2 lg:px-6 py-1 grid-cols-2 gap-4">
								<dt className="text-sm text-white font-bold">
									<SharingContainer
										title="#esmatlas"
										text="An open atlas of 772 million predicted metagenomic protein structures"
										shareableImgFile={shareableImgFile}
									/>
								</dt>
							</div>
						</div>
					</dl>
				</div>
				<div className="flex flex-row md:hidden p-1 items-center justify-between">
					<div className="flex items-center">
						{" "}
						Scroll <BsArrowDown className="mx-2" />
					</div>

					<SharingContainer
						title="#esmatlas"
						text="An open atlas of 772 million predicted metagenomic protein structures"
						shareableImgFile={shareableImgFile}
					/>
				</div>
			</div>
		</div>
	);
};

export default MetadataMenu;
