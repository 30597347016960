/*
 * (c) Meta Platforms, Inc. and its affiliates.
 *
 */

import React from "react";

enum ActionSearch {
	FoldSequence = "fold",
	MgnifyID = "atlas_lookup",
	SearchStructure = "search_structure",
	SearchSequence = "search_sequence",
}

interface ResourceDescriptionProps {
	action: string;
	copyObject: any;
	pdbFormatText: string;
	setEntry: (entry: string) => void;
	fetchPdbFileExample: (url: string) => void;
}

const ResourceDescription = ({
	action,
	copyObject,
	pdbFormatText,
	setEntry,
	fetchPdbFileExample,
}: ResourceDescriptionProps) => {
	const inputSamplesBlock = () => {
		switch (action) {
			case ActionSearch.FoldSequence:
				return [
					//{
					//name: "Lysozyme",
					//entry:
					//">Lysozyme - PDB 1DPX\nKVFGRCELAAAMKRHGLDNYRGYSLGNWVCAAKFESNFNTQATNRNTDGSTDYGILQINSRWWCNDGRTPGSRNLCNIPCSALLSSDITASVNCAKKIVSDGNGMNAWVAWRNRCKGTDVQAWIRGCRL",
					//},
					//{
					//name: "Hydrogenase",
					//entry:
					//">Hydrogenase - PDB \nMKIAILGAGCYRTHAAAGITNFMRACEVAKEVGKPEIALTHSSITYGAELLHLVPDVKEVIVSDPCFAEEPGLVVIDEFDPKEVMEAHLSGNPESIMPKIREVVKAKAKELPKPPKACIHLVHPEDVGLKVTSDDREAVEGADIVITWLPKGNKQPDIIKKFADAIPEGAIVTHACTIPTTKFAKIFKDLGREDLNITSYHPGCVPEMKGQVYIAEGYASEEAVNKLYEIGKIARGKAFKMPANLIGPVCDMCSAVTATVYAGLLAYRDAVTKILGAPADFAQMMADEALTQIHNLMKEKGIANMEEALDPAALLGTADSMCFGPLAEILPTALKVLEVHKVVEEEGKTKCEIMSQKE",
					//},
					{
						name: "Plastic degradation protein - PETase",
						entry:
							">PETase\nMGSSHHHHHHSSGLVPRGSHMRGPNPTAASLEASAGPFTVRSFTVSRPSGYGAGTVYYPTNAGGTVGAIAIVPGYTARQSSIKWWGPRLASHGFVVITIDTNSTLDQPSSRSSQQMAALRQVASLNGTSSSPIYGKVDTARMGVMGWSMGGGGSLISAANNPSLKAAAPQAPWDSSTNFSSVTVPTLIFACENDSIAPVNSSALPIYDSMSRNAKQFLEINGGSHSCANSGNSNQALIGKKGVAWMKRFMDNDTRYSTFACENPNSTRVSDFRTANCSLEDPAANKARKEAELAAATAEQ",
					},
					{
						name: "Antifreeze protein - 1EZG",
						entry:
							">1EZG Antifreeze protein\nQCTGGADCTSCTGACTGCGNCPNAVTCTNSQHCVKANTCTGSTDCNTAQTCTNSKDCFEANTCTDSTNCYKATACTNSSGCPGH",
					},
					{
						name: "AI-generated protein - 8CYK",
						entry:
							">Hallucinated protein HALC1_878\nMSGMKKLYEYTVTTLDEFLEKLKEFILNTSKDKIYKLTITNPKLIKDIGKAIAKAAEIADVDPKEIEEMIKAVEENELTKLVITIEQTDDKYVIKVELENEDGLVHSFEIYFKNKEEMEKFLELLEKLISKLSGS",
					},
					{
						name: "7-bladed propeller fold - Neuraminidase",
						entry:
							">Neuraminidase\nVKLAGNSSLCPINGWAVYSKDNSIRIGSKGDVFVIREPFISCSHLECRTFFLTQGALLNDKHSNGTVKDRSPHRTLMSCPVGEAPSPYNSRFESVAWSASACHDGTSWLTIGISGPDNGAVAVLKYNGIITDTIKSWRNNILRTQESECACVNGSCFTVMTDGPSNGQASYKIFKMEKGKVVKSVELDAPNYHYEECSCYPNAGEITCVCRDNWHGSNRPWVSFNQNLEYQIGYICSGVFGDNPRPNDGTGSCGPVSSNGAYGVKGFSFKYGNGVWIGRTKSTNSRSGFEMIWDPNGWTETDSSFSVKQDIVAITDWSGYSGSFVQHPELTGLDCIRPCFWVELIRGRPKESTIWTSGSSISFCGVNSDTVGWSWPDGAELPFTIDK",
					},
				];
			case ActionSearch.MgnifyID:
				return [
					{ name: "MGYP000911143359", entry: "MGYP000911143359" },
					{ name: "MGYP000706186022", entry: "MGYP000706186022" },
					{ name: "MGYP000936678158", entry: "MGYP000936678158" },
				];
			case ActionSearch.SearchStructure:
				return [
					{
						name: "PETase: 5XJH",
						entry: "5XJH.pdb",
						url: "https://files.rcsb.org/download/5XJH.pdb",
					},
				];
			case ActionSearch.SearchSequence:
				return [
					{
						name: "Cas9 Enzyme",
						entry:
							">Cas9 Enzyme\nGSGHMDKKYSIGLAIGTNSVGWAVITDEYKVPSKKFKVLGNTDRHSIKKNLIGALLFDSGETAEATRLKRTARRRYTRRKNRILYLQEIFSNEMAKVDDSFFHRLEESFLVEEDKKHERHPIFGNIVDEVAYHEKYPTIYHLRKKLVDSTDKADLRLIYLALAHMIKFRGHFLIEGDLNPDNSDVDKLFIQLVQTYNQLFEENPINASGVDAKAILSARLSKSRRLENLIAQLPGEKKNGLFGNLIALSLGLTPNFKSNFDLAEDAKLQLSKDTYDDDLDNLLAQIGDQYADLFLAAKNLSDAILLSDILRVNTEITKAPLSASMIKRYDEHHQDLTLLKALVRQQLPEKYKEIFFDQSKNGYAGYIDGGASQEEFYKFIKPILEKMDGTEELLVKLNREDLLRKQRTFDNGSIPHQIHLGELHAILRRQEDFYPFLKDNREKIEKILTFRIPYYVGPLARGNSRFAWMTRKSEETITPWNFEEVVDKGASAQSFIERMTNFDKNLPNEKVLPKHSLLYEYFTVYNELTKVKYVTEGMRKPAFLSGEQKKAIVDLLFKTNRKVTVKQLKEDYFKKIEEFDSVEISGVEDRFNASLGTYHDLLKIIKDKDFLDNEENEDILEDIVLTLTLFEDREMIEERLKTYAHLFDDKVMKQLKRRRYTGWGRLSRKLINGIRDKQSGKTILDFLKSDGFANRNFMQLIHDDSLTFKEDIQKAQVSGQGDSLHEHIANLAGSPAIKKGILQTVKVVDELVKVMGRHKPENIVIEMARENQTTQKGQKNSRERMKRIEEGIKELGSQILKEHPVENTQLQNEKLYLYYLQNGRDMYVDQELDINRLSDYDVDAIVPQSFLKDDSIDNKVLTRSDKNRGKSDNVPSEEVVKKMKNYWRQLLNAKLITQRKFDNLTKAERGGLSELDKAGFIKRQLVETRQITKHVAQILDSRMNTKYDENDKLIREVKVITLKSKLVSDFRKDFQFYKVREINNYHHAHDAYLNAVVGTALIKKYPKLESEFVYGDYKVYDVRKMIAKSEQEIGKATAKYFFYSNIMNFFKTEITLANGEIRKRPLIETNGETGEIVWDKGRDFATVRKVLSMPQVNIVKKTEVQTGGFSKESILPKRNSDKLIARKKDWDPKKYGGFDSPTVAYSVLVVAKVEKGKSKKLKSVKELLGITIMERSSFEKNPIDFLEAKGYKEVKKDLIIKLPKYSLFELENGRKRMLASAGELQKGNELALPSKYVNFLYLASHYEKLKGSPEDNEQKQLFVEQHKHYLDEIIEQISEFSKRVILADANLDKVLSAYNKHRDKPIREQAENIIHLFTLTNLGAPAAFKYFDTTIDRKRYTSTKEVLDATLIHQSITGLYETRIDLSQLGGD",
					},
					{
						name: "Plastic degradation protein - PETase",
						entry:
							">PETase\nMGSSHHHHHHSSGLVPRGSHMRGPNPTAASLEASAGPFTVRSFTVSRPSGYGAGTVYYPTNAGGTVGAIAIVPGYTARQSSIKWWGPRLASHGFVVITIDTNSTLDQPSSRSSQQMAALRQVASLNGTSSSPIYGKVDTARMGVMGWSMGGGGSLISAANNPSLKAAAPQAPWDSSTNFSSVTVPTLIFACENDSIAPVNSSALPIYDSMSRNAKQFLEINGGSHSCANSGNSNQALIGKKGVAWMKRFMDNDTRYSTFACENPNSTRVSDFRTANCSLEDPAANKARKEAELAAATAEQ",
					},
				];
			default:
				return [
					{
						name: "Lysozyme",
						entry:
							">hen_egg_white_lysozyme_1dpx\nKVFGRCELAAAMKRHGLDNYRGYSLGNWVCAAKFESNFNTQATNRNTDGSTDYGILQINSRWWCNDGRTPGSRNLCNIPCSALLSSDITASVNCAKKIVSDGNGMNAWVAWRNRCKGTDVQAWIRGCRL",
					},
					{
						name: "Hydrogenase",
						entry:
							">hydrogenasej\nMKIAILGAGCYRTHAAAGITNFMRACEVAKEVGKPEIALTHSSITYGAELLHLVPDVKEVIVSDPCFAEEPGLVVIDEFDPKEVMEAHLSGNPESIMPKIREVVKAKAKELPKPPKACIHLVHPEDVGLKVTSDDREAVEGADIVITWLPKGNKQPDIIKKFADAIPEGAIVTHACTIPTTKFAKIFKDLGREDLNITSYHPGCVPEMKGQVYIAEGYASEEAVNKLYEIGKIARGKAFKMPANLIGPVCDMCSAVTATVYAGLLAYRDAVTKILGAPADFAQMMADEALTQIHNLMKEKGIANMEEALDPAALLGTADSMCFGPLAEILPTALKVLEVHKVVEEEGKTKCEIMSQKE",
					},
					{
						name: "Myoglobin",
						entry:
							">myoglobin_1mbn\nVLSEGEWQLVLHVWAKVEADVAGHGQDILIRLFKSHPETLEKFDRFKHLKTEAEMKASEDLKKHGVTVLTALGAILKKKGHHEAELKPLAQSHATKHKIPIKYLEFISEAIIHVLHSRHPGDFGADAQGAMNKALELFRKDIAAKYKELGYQG",
					},
				];
		}
	};

	return (
		<div className="lg:block w-11/12 lg:w-4/6 mb-4 mx-auto">
			<h3 className="text-sm lg:ml-[180px]">{copyObject?.value_prop}</h3>
			<p className="sm:ml-[135px] lg:ml-[180px] text-sm">Try an example:</p>
			<div className="flex flex-wrap w-11/12 lg:w-4/6 sm:ml-[135px] lg:ml-[180px] my-1">
				{inputSamplesBlock().map((i: any, idx: number) => (
					<button
						key={idx}
						className="btn btn-xs btn-info text-white normal-case mr-2 my-1"
						onClick={() => {
							if (action === ActionSearch.SearchStructure) {
								fetchPdbFileExample(i.url);
							}
							setEntry(i.entry);
						}}
					>
						{i.name}
					</button>
				))}
			</div>
			{action === ActionSearch.SearchStructure && pdbFormatText && (
				<div className="flex lg:ml-48 mt-4">
					<textarea
						id="display-input_"
						name="display-input_"
						className={`textarea textarea-bordered w-full bg-gray/20 disabled:bg-base-100/10 border-gray-200 rounded-r-md text-sm focus:z-10 dark:bg-gray-800 dark:border-gray-700 text-black input-sm h-[20vh] lg:input-md lg:h-[25vh] ${
							pdbFormatText === "" &&
							"outline-dashed outline-2 outline-offset-2"
						}`}
						placeholder="Select or drop a file to upload."
						rows={3}
						readOnly
						value={pdbFormatText}
					/>
				</div>
			)}
		</div>
	);
};

export default ResourceDescription;
