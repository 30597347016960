/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import axios, { AxiosRequestConfig, AxiosError } from "axios";
import React from "react";

const apiHost = process.env.REACT_APP_LAMBDA_API;
const mmseqApiHost = process.env.REACT_APP_MMSEQ_API;
const foldSeekApiHost = process.env.REACT_APP_FOLDSEEK_API;
const mmseq_db = process.env.REACT_APP_MMSEQ_DB;
const foldseek_db = process.env.REACT_APP_FOLDSEEK_DB;

export function useApi(onError: (error: AxiosError) => void) {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	enum ApiPath {
		FetchPredictedStructure = "fetchPredictedStructure",
		FetchConfidencePrediction = "fetchConfidencePrediction",
		FetchMetrics = "fetchMetrics",
		FoldSequence = "foldSequence/v1/cif", // This Route only exists in prod, update to foldSequence/v1 to test in dev.
		FoldSequencePDB = "foldSequence/v1/pdb",
		Ticket = "ticket",
	}

	const DEFAULT_CONFIG = {
		timeout: 60000,
		"accept-encoding": "gzip",
		"Content-Type": "application/x-www-form-urlencoded",
	};

	/**
	 * ESM_HUB API
	 * @param path
	 * @param data
	 * @param onResult
	 * @param config
	 */
	async function invokeGET(
		path: string,
		data: any,
		onResult: (result: any) => void,
		config: AxiosRequestConfig = DEFAULT_CONFIG
	) {
		try {
			setIsLoading(true);

			const result = await axios.get(`${apiHost}/${path}/${data}`);
			onResult(result.data);
		} catch (error) {
			console.log(error);
			onError(error as AxiosError);
		} finally {
			setIsLoading(false);
		}
	}

	/**
	 * ESM_HUB LAMBDAS API
	 * @param path
	 * @param data
	 * @param onResult
	 * @param config
	 * @returns
	 */
	async function invokePOST(
		path: string,
		data: any,
		onResult: (result: any) => void,
		config: AxiosRequestConfig
	) {
		try {
			setIsLoading(true);

			const result = await axios.post(`${apiHost}/${path}`, data, config);
			onResult(result.data);
			return result.data;
		} catch (error) {
			console.log(error);
			onError(error as AxiosError);
		} finally {
			setIsLoading(false);
		}
	}

	/**
	 * SEARCH FOLDSEEK API
	 * @param path api path.
	 * @param data string or encoded data.
	 * @param type type of search, structure or sequence.
	 * @param onResult a callback for the result.
	 * @param config
	 * @returns
	 */
	async function invokeAPI_POST(
		path: string,
		data: any,
		type: "sequence" | "structure",
		onResult: (result: any) => void,
		config: AxiosRequestConfig = DEFAULT_CONFIG
	) {
		try {
			setIsLoading(true);
			if (type === "sequence") {
				const result = await axios.post(
					`${mmseqApiHost}/${path}`,
					data,
					config
				);
				onResult(result.data);
				return result.data;
			} else {
				const result = await axios.post(
					`${foldSeekApiHost}/${path}`,
					data,
					config
				);
				onResult(result.data);
				return result.data;
			}
		} catch (error) {
			console.log(error);
			onError(error as AxiosError);
		} finally {
			setIsLoading(false);
		}
	}

	/**
	 * SEARCH FOLDSEEK API
	 * @param path
	 * @param onResult
	 */
	async function invokeAPI_GET(
		path: string,
		type: "sequence" | "structure",
		onResult: (result: any) => void
	) {
		try {
			setIsLoading(true);
			if (type === "sequence") {
				const result = await axios.get(`${mmseqApiHost}/${path}`);
				onResult(result.data);
			} else {
				const result = await axios.get(`${foldSeekApiHost}/${path}`);
				onResult(result.data);
			}
		} catch (error) {
			console.log(error);
			onError(error as AxiosError);
		} finally {
			setIsLoading(false);
		}
	}

	// ************************* //
	// **** ENDPOINTS CALLS **** //
	// ************************* //

	const fetchPredictedStructure = async function (
		data: string,
		onResult: (result: any) => void
	) {
		await invokeGET(ApiPath.FetchPredictedStructure, data, onResult);
	};

	const fetchConfindencePrediction = async function (
		data: string,
		onResult: (result: any) => void
	) {
		await invokeGET(ApiPath.FetchConfidencePrediction, data, onResult);
	};

	const fetchMetrics = async function (
		data: string,
		onResult: (result: any) => void
	) {
		await invokeGET(ApiPath.FetchMetrics, data, onResult);
	};

	// POST request to fold sequence.
	const foldSequence = async function (
		seq: string,
		onResult: (result: any) => void
	) {
		const raw_payload = seq;
		const res = await invokePOST(ApiPath.FoldSequence, raw_payload, onResult, {
			headers: {
				"Content-Type": "text/plain",
			},
		});
		return res;
	};

	// POST request to fold sequence.
	const foldSequencePDB = async function (
		seq: string,
		onResult: (result: any) => void
	) {
		const raw_payload = seq;
		const res = await invokePOST(
			ApiPath.FoldSequencePDB,
			raw_payload,
			onResult,
			{
				headers: {
					"Content-Type": "text/plain",
				},
			}
		);
		return res;
	};

	// ****** Foldseek and MMseq2 calls ****** //

	// GET request to fetch databases ids.
	const getAllDB = async function (
		data: string,
		type: "sequence" | "structure",
		onResult: (result: any) => void
	) {
		await invokeAPI_GET("databases/all", type, onResult);
	};

	// POST request to get a job ticket from a sequence.
	const reqTicketFromSequence = async function (
		seq: string,
		fasta_header: any,
		onResult: (result: any) => void
	) {
		const raw_payload = `q=%3E${fasta_header}%0A${seq}&mode=accept&email=&database%5B%5D=${mmseq_db}`;

		const res = await invokeAPI_POST(
			ApiPath.Ticket,
			raw_payload,
			"sequence",
			onResult,
			DEFAULT_CONFIG
		);
		return res;
	};

	// POST request to get a job ticket from a .pdb file
	const reqTicketFromPDBFile = async function (
		encoededPdb: string,
		onResult: (result: any) => void
	) {
		const raw_payload = `q=${encoededPdb}&mode=3diaa&email=&database%5B%5D=${foldseek_db}`;
		const res = await invokeAPI_POST(
			ApiPath.Ticket,
			raw_payload,
			"structure",
			onResult,
			DEFAULT_CONFIG
		);
		return res;
	};

	// GET request to fetch the status of a ticket.
	const getTicketStatus = async function (
		ticket_id: string,
		type: "sequence" | "structure",
		onResult: (result: any) => void
	) {
		await invokeAPI_GET(`${ApiPath.Ticket}/${ticket_id}`, type, onResult);
	};

	// GET request to fetch results after passing job ticket id.
	const getSearchResult = async function (
		ticket_id: string,
		type: "sequence" | "structure",
		onResult: (result: any) => void
	) {
		await invokeAPI_GET(`result/${ticket_id}/0`, type, onResult);
	};

	return {
		isLoading,
		fetchPredictedStructure,
		fetchConfindencePrediction,
		fetchMetrics,
		reqTicketFromSequence,
		reqTicketFromPDBFile,
		getTicketStatus,
		getAllDB,
		getSearchResult,
		foldSequence,
		foldSequencePDB,
	};
}
