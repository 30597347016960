/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import * as d3 from "d3";

export const trunc = (str: string, len: number) =>
	str?.length > len ? str.substring(0, len - 1) + "..." : str;

/**
 * Generates a hex color code ramdonmly
 * @returns hex color code
 */
export const random_hex_color_code = () => {
	let n = (Math.random() * 0xfffff * 1000000).toString(16);
	return "#" + n.slice(0, 6);
};

/**
 * A custom color palette
 */

function quadraticIn(t: number, c: number = 1): number {
	return c * t * t;
}

function quadraticOut(t: number, c: number = 1): number {
	return -c * t * (t - 2);
}

function quadraticInOut(t:number, c:number = 1, p:number = 10):number {
	let dt = t * 2;
    return (t < 0.5) ? c / 2 * t * t * 4 : -c / 2 * ((dt - 1) * (dt - 3) - 1);
}

let colors = [];

/*
Blue-green-yellow
const interpolate_y_g = d3.interpolateHcl("#FFFFBB", "#10C278");
const interpolate_g_b = d3.interpolateHcl("#10C278", "#5E08B5");

/* Purle-yellow
const interpolate = d3.interpolateHcl("#FFFFBB", "#471FE8");
*/

const interpolate_y_g = d3.interpolateHcl("#FFFFBB", "#10C278");
const interpolate_g_b = d3.interpolateHcl("#10C278", "#671DC6");
const offset = 0; // for offsetting d3 color presets only
const steps1 = 20;
const steps2 = 30;

for (let i = 0; i < steps1; i++) {
	const t = quadraticInOut(i / steps1);
	const c = d3.color(interpolate_y_g(offset + t))?.formatHex();
	// const c = d3.interpolateInferno(offset + ((1 - offset) * (100 - i)) / 100);
	colors.push(c || "#0A0260");
}

for (let i = 0; i < steps2; i++) {
	const t = (i / steps2);
	const c = d3.color(interpolate_g_b(offset + t))?.formatHex();
	// const c = d3.interpolateInferno(offset + ((1 - offset) * (100 - i)) / 100);
	colors.push(c || "#0A0260");
}

export const colorsScale = colors.slice();

// export const colorsScale = [
//   "#D61C4E", // Redish
//   "#2e4186",
//   "#85aec0",
//   "#6789ae",
// ];

/**
 * Generate random number
 * @param max max vuale allowed
 * @returns
 */
export const getRandomInt = (max: number) => {
	return Math.floor(Math.random() * max);
};

export type HeatMapDataPoint = {
	x: number;
	y: number;
	value: number;
};

/**
 * Format data to visualize in heatmap.
 * @param arr
 * @returns
 */
export const formatHeatMapData = (arr: number[][]) => {
	const formatted: HeatMapDataPoint[] = [];
	arr.map((i, index) => {
		i.forEach((el, el_idx) => {
			formatted.push({ x: index + 1, y: el_idx + 1, value: el });
		});
		return {};
	});

	return formatted;
};

/*
 * Used to Validate input on Resource inputs
 * Validation for Fold Sequence max len of 400
 * Validation for Search Sequence max len of 1500
 * Entry.length to reduce API fetch time
 * charCode 62 represents '>'
 */
export const validEntry = ({
	sequence,
	actionType,
}: {
	sequence: string;
	actionType: string;
}) => {
	if (
		!sequence ||
		sequence.length > (actionType === "search_sequence" ? 1500 : 400)
	)
		return false;
	const requiredChar: string[] = [
		"L",
		"A",
		"G",
		"V",
		"S",
		"E",
		"R",
		"T",
		"I",
		"D",
		"P",
		"K",
		"Q",
		"N",
		"F",
		"Y",
		"M",
		"H",
		"W",
		"C",
	];
	const isValid: boolean = sequence
		.split("")
		.every((char) => requiredChar.includes(char) || char.charCodeAt(0) === 62);
	return isValid;
};
