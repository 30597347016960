/*
 * (c) Meta Platforms, Inc. and its affiliates.
 */

import React from "react";
import MainNavbar from "../navbars/MainNavbar";
import Footer from "../footers/Footer";
import ClusterMap from "../charts/ClusterMap";
import MetadataMenu from "../cards/MetadataMenu";
import ColorScaleCard from "../cards/ColorScaleCard";
import { DataContext, DataPoint } from "../../context/DataContext";
import { useZoomIncrementer } from "../../hooks";
import { useTitle, useDebounce } from "react-use";

function MobileExplorerPage() {
	useTitle("Explore - ESM Metagenomic Atlas");
	const { data } = React.useContext(DataContext);
	const zoomLevel = useZoomIncrementer(22);
	const [showName, setShowName] = React.useState<boolean>(false);
	const [pointData, setPointData] = React.useState<DataPoint | null>(null);

	/**
	 * Debounce the display of the protein image
	 * card to avoid excessive blinking.
	 */
	useDebounce(
		() => {
			setShowName(true);
		},
		250,
		[pointData]
	);

	/**
	 * On pointhover, updates the mgnifyID to display and
	 * show the image card.
	 */
	const pointoverHandler = React.useCallback((pointData: DataPoint) => {
		setPointData(pointData);
	}, []);

	return (
		<div className="page-container bg-black">
			<MainNavbar position="fixed" variant="dark" />
			{showName && pointData ? (
				<MetadataMenu
					sideBoxData={pointData}
					showMetadataMenu={true}
					deselectHandler={() => {}}
				/>
			) : null}
			<ClusterMap data={data} pointoverHandler={pointoverHandler} />
			<ColorScaleCard
				onIncrement={zoomLevel.increment}
				onDecrement={zoomLevel.decrement}
			/>
			<Footer position="fixed" />
		</div>
	);
}

export default MobileExplorerPage;
