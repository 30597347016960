/*
 * (c) Meta Platforms, Inc. and its affiliates.
 *
 */

import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { CgArrowTopRight } from "react-icons/cg";
import { FaSearch } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTitle } from "react-use";
import LoadingSpinner from "../../assets/custom_icons/loading_spinner.gif";
import { DataContext } from "../../context/DataContext";
import { useOutsideClick } from "../../hooks";
import { useApi } from "../../hooks/useApi";
import { validEntry } from "../../utils/Helpers";
import { CopyObject, resourcesCopy } from "../../utils/StaticText";
import Footer from "../footers/Footer";
import ErrorAlert from "../miscellaneous/ErrorAlert";
import ResourceDescription from "../miscellaneous/ResourceDescription";
import MainNavbar from "../navbars/MainNavbar";
//import sampleFile from "../../assets/4oo8.pdb";

enum ActionSearch {
	FoldSequence = "fold",
	MgnifyID = "atlas_lookup",
	SearchStructure = "search_structure",
	SearchSequence = "search_sequence",
}

type ActionSearchParam =
	| "fold"
	| "atlas_lookup"
	| "search_structure"
	| "search_sequence";

const FoldSeqPage = () => {
	useTitle("Resources - ESM Metagenomic Atlas");
	const { setPdbDataUrl } = React.useContext(DataContext);
	const navigate = useNavigate();
	const location = useLocation();
	const timerRef = React.useRef<NodeJS.Timeout>(null!);
	const secondaryTimerRef = React.useRef<NodeJS.Timeout>(null!);

	const [action, setAction] = React.useState<ActionSearchParam | string>(
		"fold"
	);
	const [showOptions, setOptionsMenu] = React.useState<boolean>(false);
	const [entry, setEntry] = React.useState<string>();
	const [isInvalidEntry, setIsInvalidEntry] = React.useState<boolean>(false);
	const [pdbFormatText, setPdbFormatText] = React.useState<string>("");
	const [textEncoded, setTextEncoded] = React.useState<string>();
	const [isRunning, setIsRunning] = React.useState<boolean>(false);
	const [stillRunning, setStillRunning] = React.useState<boolean>(false);
	const [showError, setShowError] = React.useState<string | null>(null);
	const [copyObject, setCopyObject] = React.useState<CopyObject>();
	const [invalidSizeFile, setInvalidSizeFile] = React.useState<boolean>(false);
	const controlsRef = useOutsideClick(() => {
		setOptionsMenu(false);
	});
	const {
		isLoading,
		reqTicketFromSequence,
		reqTicketFromPDBFile,
		getTicketStatus,
		foldSequence,
	} = useApi((err: any) => {
		err.response?.data?.status === "RATELIMIT"
			? setShowError("Rate Exceeded, please try again in a few.")
			: setShowError("There was an error.");
	});

	const [currentQueryParameters, setSearchParams] = useSearchParams();
	const newQueryParameters: URLSearchParams = new URLSearchParams();

	React.useEffect(() => {
		setAction(currentQueryParameters.get("action") as string);
		setCopyObject(
			resourcesCopy.find((x) => x.name === currentQueryParameters.get("action"))
		);

		return () => {
			clearInterval(timerRef.current);
			clearTimeout(secondaryTimerRef.current);
		};
	}, [currentQueryParameters]);

	React.useEffect(() => {
		window.history.pushState(null, "", `/resources${location.search}`);
		const locationReplace = (e: PopStateEvent) => {
			window.location.replace(location.search);
		};
		window.addEventListener("popstate", locationReplace);
		return window.removeEventListener("popstate", locationReplace);
	}, [location.search]);

	const actionMapper = () => {
		switch (action) {
			case ActionSearch.FoldSequence:
				return "Fold Sequence";
			case ActionSearch.MgnifyID:
				return "Lookup";
			case ActionSearch.SearchStructure:
				return "Search Structure";
			case ActionSearch.SearchSequence:
				return "Search Sequence";
			default:
				return "Fold Sequence";
		}
	};

	/**
	 * Handles dropdown selection.
	 * @param action
	 */
	const handleSelect = (action: ActionSearchParam) => {
		setAction(action);
		newQueryParameters.set("action", action);
		setSearchParams(newQueryParameters);
		setOptionsMenu(false);
		setEntry("");
	};

	/**
	 * Handles text input/text area on search.
	 * @param e
	 */
	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (action === ActionSearch.MgnifyID) {
			setEntry(e.target.value.toUpperCase());
		}
		if (
			action === ActionSearch.FoldSequence ||
			action === ActionSearch.SearchSequence
		) {
			setEntry(e.target.value);
		}
	};

	/**
	 * Triggres when key Enter is hit, only on MgnifyID (single line input).
	 * @param e
	 */
	const handleKeyDown = (
		e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleOnClick();
		}
	};

	/**
	 * Controls the search button logic.
	 * @returns
	 */
	const handleOnClick = async () => {
		if (!entry) return;
		const { fasta_header, sequence } = sequenceFormatter(entry);
		if (action === ActionSearch.MgnifyID) {
			navigate(`/resources/detail/${entry}`, { replace: true });
		}
		if (action === ActionSearch.SearchSequence) {
			validEntry({ sequence, actionType: ActionSearch.SearchSequence })
				? runSearchSequence({ fasta_header, sequence })
				: setIsInvalidEntry(true);
		}
		if (action === ActionSearch.SearchStructure) {
			postPDBFileData();
		}
		if (action === ActionSearch.FoldSequence) {
			if (validEntry({ sequence, actionType: ActionSearch.FoldSequence })) {
				await runFoldSequence(sequence);
				navigate(
					`/resources/fold/result?fasta_header=${fasta_header}&sequence=${sequence}`,
					{
						replace: true,
					}
				);
			} else {
				setIsInvalidEntry(true);
			}
		}
	};

	/**
	 * Invoke API to fold sequence, this function also creates a blob from
	 * the a pdb file, and turn it into a url to visualize in the molstar component.
	 */
	const runFoldSequence = async (sequence: string) => {
		await foldSequence(sequence, async (data) => {
			let blob = new Blob([data], { type: "chemical/x-pdb" });
			const objectUrl = URL.createObjectURL(blob);
			setPdbDataUrl(objectUrl);
		});
	};

	/**
	 * Displays a message when a search is taking longer than 10 seoconds.
	 * @param e
	 */
	const sendIndicatorMessage = () => {
		secondaryTimerRef.current = setTimeout(() => setStillRunning(true), 10000);
	};

	/**
	 * Handles the logic to read and encoed file content to search against db.
	 * @param file
	 */
	const handleFileUpload = async (file: File) => {
		if (file) {
			const filename = file.name;
			if (file.size > 3145728) {
				setInvalidSizeFile(true);
				return;
			}

			setEntry(filename);

			const readFile = (file: File) => {
				return new Promise((acc, err) => {
					const reader = new FileReader();
					reader.onload = (event: ProgressEvent<FileReader>) => {
						acc(event.target?.result);
					};
					reader.onerror = (err: ProgressEvent<FileReader>) => {
						console.log(err);
					};
					reader.readAsText(file);
				});
			};

			const temp = await readFile(file);
			const encoded = encodeURIComponent(temp as string);

			setPdbFormatText(temp as string);
			setTextEncoded(encoded);
		}
	};

	const sequenceFormatter = (entry: string) => {
		if (entry?.indexOf("\n") === -1) {
			let fasta_header = ">unnamed";
			let sequence = entry.toUpperCase();
			return { fasta_header: fasta_header, sequence: sequence };
		} else {
			let fasta_header = entry?.split(/\r?\n/)[0];
			let sequence = entry?.split(/\r?\n/)[1].toUpperCase();
			return { fasta_header: fasta_header, sequence: sequence };
		}
	};

	/**
	 * Fetch .pdb files to populate the exmaples in the search
	 * structure option.
	 * @param url
	 */
	const fetchPdbFileExample = async (url: string) => {
		const response = await fetch(url);
		const data = await response.text();
		const encoded = encodeURIComponent(data as string);

		setPdbFormatText(data as string);
		setTextEncoded(encoded);
	};

	/**
	 * Invokes API to make a POST request to generate a ticket
	 * on a neq query to search a sequence gains the MMseq2 database.
	 */
	const runSearchSequence = async ({
		fasta_header,
		sequence,
	}: {
		fasta_header: string;
		sequence: string;
	}) => {
		const ticketID = await reqTicketFromSequence(
			sequence, //"MPKIIEAIYENGVFKPLQKVDLKEGEKAKIVLESISDKTFGILKASETEIKKVLEEIDDFWGVC"
			fasta_header, //"TEST"
			(data) => {
				console.log(data);
			}
		);
		if (ticketID?.status === "PENDING" || ticketID?.status === "RUNNING") {
			sendIndicatorMessage();
			setIsRunning(true);
			timerRef.current = setInterval(
				async () =>
					await getTicketStatus(ticketID.id, "sequence", (data) => {
						if (data?.status === "COMPLETE") {
							navigate(
								`/resources/search_sequence?query_id=${ticketID.id}&fasta_header=${fasta_header}&sequence=${sequence}`,
								{ replace: true }
							);
						}
					}),
				4000
			);
		} else if (ticketID?.status === "COMPLETE") {
			navigate(
				`/resources/search_sequence?query_id=${ticketID.id}&fasta_header=${fasta_header}&sequence=${sequence}`,
				{ replace: true }
			);
		} else {
			console.log("Error loading search result");
		}
	};

	/**
	 * Invokes API to search structure with a .pdb file
	 * against foldseek database.
	 */
	const postPDBFileData = async () => {
		const ticketID = await reqTicketFromPDBFile(textEncoded!, (data) => {
			console.log(data);
		});

		if (ticketID?.status === "PENDING" || ticketID?.status === "RUNNING") {
			sendIndicatorMessage();
			setIsRunning(true);
			timerRef.current = setInterval(
				async () =>
					await getTicketStatus(ticketID.id, "structure", (data) => {
						if (data?.status === "COMPLETE") {
							navigate(`/resources/search_structure?query_id=${ticketID.id}`, {
								replace: true,
							});
						}
					}),
				4000
			);
		} else if (ticketID?.status === "COMPLETE") {
			navigate(`/resources/search_structure?query_id=${ticketID.id}`, {
				replace: true,
			});
		} else {
			console.log("Error loading search result");
		}
	};

	const learnMoreRouter = () => {
		switch (action) {
			case ActionSearch.FoldSequence:
				return "/about#fold";
			case ActionSearch.MgnifyID:
				return "/about";
			case ActionSearch.SearchStructure:
				return "/about#search";
			case ActionSearch.SearchSequence:
				return "/about#search";
			default:
				return "/about";
		}
	};

	const OptionsDropdown = (
		<div
			className="absolute left-2 top-[45px] lg:top-[60px] w-42"
			ref={controlsRef}
		>
			<ul className="menu menu-compact p-1 lg:p-2 rounded-bl-md rounded-br-md font-bold bg-primary text-white">
				<li>
					<button
						className="text-xs lg:text-sm p-2 hover:text-accent"
						onClick={() => handleSelect("fold")}
					>
						Fold Sequence
					</button>
				</li>
				<li>
					<button
						className="text-xs lg:text-sm p-2 hover:text-accent"
						onClick={() => handleSelect("atlas_lookup")}
					>
						Lookup
					</button>
				</li>
				<li>
					<button
						className="text-xs lg:text-sm p-2 hover:text-accent"
						onClick={() => handleSelect("search_sequence")}
					>
						Search Sequence
					</button>
				</li>
				<li>
					<button
						className="text-xs lg:text-sm p-2 hover:text-accent"
						onClick={() => handleSelect("search_structure")}
					>
						Search Structure
					</button>
				</li>
			</ul>
		</div>
	);

	return (
		<div className="bg-base-100 min-h-screen">
			<MainNavbar position="relative" variant="light" />
			{showError && (
				<ErrorAlert
					position="absolute"
					showError={showError}
					setShowError={setShowError}
				/>
			)}
			<section className="w-full text-primary">
				<div className="flex justify-center items-end m-2 h-10 lg:h-40"></div>
				<div className="flex flex-col justify-center content-center items-center m-2 min-h-52">
					<div className="w-11/12 lg:w-4/6 justify-start my-2 lg:my-6">
						<h3 className="text-left text-2xl lg:text-5xl font-bold mb-0 tracking-tight">
							{copyObject?.title}{" "}
							<a
								href={learnMoreRouter()}
								target="_blank"
								rel="noopener noreferrer"
							>
								<button className="btn btn-link normal-case text-xs p-0">
									Learn more&nbsp;
									<CgArrowTopRight />
								</button>
							</a>
						</h3>
						<p>{copyObject?.value_prop} </p>
					</div>
					<div className="relative flex rounded-md mt-5 w-11/12 lg:w-4/6">
						<div className="flex-none bg-white p-2 pr-0 h-12 lg:h-16 rounded-l-md">
							<button
								className="btn btn-secondary btn-sm px-2 text-xs lg:btn-md sm:text-md capitalize text-white flex justify-between items-center focus:z-10 transition-all lg:min-w-[170px]"
								onClick={() => setOptionsMenu(!showOptions)}
							>
								{actionMapper()} <IoMdArrowDropdown className="ml-3" />
							</button>
						</div>

						{showOptions && OptionsDropdown}

						<div
							className={`relative grow flex bg-white px-0 md:px-2 py-2 rounded-r-md ${
								(action === ActionSearch.FoldSequence ||
									action === ActionSearch.SearchSequence) &&
								"rounded-bl-md"
							}`}
						>
							{action === ActionSearch.FoldSequence ||
							action === ActionSearch.SearchSequence ? (
								<textarea
									id="search-input"
									name="search-input"
									className="textarea w-full bg-white disabled:bg-base-100/10 text-xs md:text-sm focus:outline-none dark:bg-gray-800 dark:border-gray-700 text-black input-sm h-[6rem] p-2 mr-14"
									placeholder={copyObject?.placeholder}
									rows={3}
									value={entry || ""}
									onChange={handleChange}
									onKeyDown={handleKeyDown}
								/>
							) : (
								<input
									type="text"
									id="search-input"
									name="search-input"
									placeholder={copyObject?.placeholder}
									className="p-2 mr-14 self-center block w-full disabled:bg-base-100/10 border-gray-200 rounded-r-md text-sm focus:z-10 focus:outline-none dark:bg-gray-800 dark:border-gray-700 text-black input-sm"
									disabled={action === "search_structure"}
									value={entry || ""}
									onChange={handleChange}
									onKeyDown={handleKeyDown}
								/>
							)}
							{action === ActionSearch.SearchStructure && (
								<div className="absolute z-10 top-0 bottom-0 left-3 right-3 flex">
									<FileUploader
										handleChange={handleFileUpload}
										name="file"
										types={["pdb"]}
										hoverTitle=""
										classes="self-center h-3/5 mr-14"
										children={
											<div
												className={`w-full h-full flex border-gray-700 rounded items-center leading-3 bg-white px-1 md:px-3 text-xs lg:text-sm cursor-pointer font-semibold text-secondary ${
													pdbFormatText === "" &&
													"outline-dotted outline-secondary outline-2 outline-offset-2"
												}`}
											>
												{entry ? (
													entry
												) : (
													<span>
														Click to browse or drag a file here
														<span className="hidden md:inline"> to upload</span>
													</span>
												)}
											</div>
										}
									/>
								</div>
							)}
							<div className="absolute top-2 right-2 flex z-20">
								<div
									className="btn btn-sm btn-ghost lg:btn-md px-2"
									onClick={handleOnClick}
								>
									<FaSearch size={"1.5em"} />
								</div>
							</div>
						</div>
					</div>
					<div
						className={`font-bold mt-1 text-error ${
							isInvalidEntry ? "alert-shown" : "alert-hidden"
						}`}
						onTransitionEnd={() => setIsInvalidEntry(false)}
					>
						Invalid entry.{" "}
						{action === ActionSearch.SearchSequence
							? " (Max length is 1500)"
							: " (Max length is 400)"}
					</div>
					<div
						className={`font-bold mt-1 text-error ${
							invalidSizeFile ? "alert-shown" : "alert-hidden"
						}`}
						onTransitionEnd={() => {
							setIsInvalidEntry(false);
							setInvalidSizeFile(false);
						}}
					>
						Invalid, The file is too big.{" "}
					</div>
					<ResourceDescription
						action={action}
						copyObject={copyObject}
						pdbFormatText={pdbFormatText}
						setEntry={setEntry}
						fetchPdbFileExample={fetchPdbFileExample}
					/>
				</div>
			</section>

			{(isLoading || isRunning) && (
				<div className="flex flex-col justify-center text-center w-full mt-10">
					<img className="w-[60px] mx-auto" src={LoadingSpinner} alt="" />
					{stillRunning && (
						<p className="text-black">
							Hang tight, search process is still running, it might take up to 2
							minutes.
						</p>
					)}
				</div>
			)}
			<Footer position="absolute" variant="light" />
		</div>
	);
};

export default FoldSeqPage;
