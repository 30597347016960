/*
 * (c) Meta Platforms, Inc. and its affiliates.
 *
 */

import {
	createColumnHelper,
	ExpandedState,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { IoMdDownload } from "react-icons/io";
import { Link, useSearchParams } from "react-router-dom";

type Results = {
	db: string;
	query: string;
	score: number | null;
	seqId: number | null;
	target: string;
	eval: number | null;
	qStartPos: number | null;
	qEndPos: number | null;
	qLen: number | null;
	dbStartPos: number | null;
	dbEndPos: number | null;
	dbLen: number | null;
	alignments: Results[];
};

interface StructureResultsTableProps {
	data: Results[];
}

const columnHelper = createColumnHelper<Results>();

const columns = [
	columnHelper.accessor((row) => row.target, {
		id: "Target",
		header: () => "Target",
		cell: (info) => (
			<Link
				className="font-bold hover:decoration-2 hover:underline"
				to={`/resources/detail/${info.getValue()?.replace(".pdb.gz", "")}`}
			>
				{info.getValue()?.replace(".pdb.gz", "")}
			</Link>
		),
	}),
	columnHelper.accessor("seqId", { header: () => "Seq. ID" }),
	columnHelper.accessor("score", {
		header: () => "Score",
		cell: (info) => info.renderValue(),
	}),
	columnHelper.accessor("eval", {
		header: () => "E-Value",
		cell: (info) => info.getValue()?.toExponential(),
	}),
	columnHelper.accessor((row) => row, {
		id: "QPos",
		header: () => "Query Pos.",
		cell: (info) => (
			<div>
				{info?.getValue()?.alignments !== null && info.row.depth === 1 ? (
					<span>{`${info.getValue().qStartPos}-${info.getValue().qEndPos} (${
						info.getValue().qLen
					})`}</span>
				) : (
					""
				)}
			</div>
		),
	}),
	columnHelper.accessor((row) => row, {
		id: "TPos",
		header: () => "Target Pos.",
		cell: (info) => (
			<div>
				{info?.getValue()?.alignments !== null && info.row.depth === 1 ? (
					<span>{`${info.getValue().dbStartPos}-${info.getValue().dbEndPos} (${
						info.getValue().dbLen
					})`}</span>
				) : (
					""
				)}
			</div>
		),
	}),
];

const StructureResultsTable = ({ data }: StructureResultsTableProps) => {
	const [expanded, setExpanded] = React.useState<ExpandedState>(true);
	const [downloadData, setDownloadData] = React.useState<string | null>(null);
	const [currentQueryParameters] = useSearchParams();
	const table = useReactTable({
		data,
		columns,
		state: {
			expanded,
		},
		onExpandedChange: setExpanded,
		getSubRows: (row: Results) => row.alignments,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		debugTable: true,
	});

	React.useEffect(() => {
		const createObjURL = async () => {
			const blob = new Blob([JSON.stringify(data)], {
				type: "application/json",
			});
			const objURL = URL.createObjectURL(blob);
			setDownloadData(objURL);
		};
		createObjURL();
	}, [data]);

	return (
		<React.Fragment>
			{downloadData && (
				<div className="flex justify-between px-4 lg:px-20 py-10">
					<h2 className="text-primary font-semibold text-3xl">Results</h2>
					<a
						className="btn btn-sm bg-secondary normal-case text-white"
						href={downloadData!}
						download={`results_${
							currentQueryParameters.get("query_id") || "_untitled"
						}.json`}
						target="_blank"
						rel="noreferrer"
					>
						<IoMdDownload className="mr-2" size={"1.25em"} />
						Download results
					</a>
				</div>
			)}
			<div className="overflow-x-auto px-4 lg:px-20">
				<table className="table seq_table table-compact text-black active w-full mt-2">
					<thead
						className="lowercase text-left"
						style={{ textTransform: "lowercase" }}
					>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext()
											  )}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody className="t_body_seq left_float_border">
						{table.getRowModel().rows.map((row) => (
							<tr key={row.id} className="hover">
								{row.getVisibleCells().map((cell) => (
									<td key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</React.Fragment>
	);
};

export default StructureResultsTable;
