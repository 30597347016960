/*
 * (c) Meta Platforms, Inc. and its affiliates.
 *
 */

import React from "react";

const useOutsideClick = (callback: () => void) => {
	const ref = React.useRef<HTMLDivElement>(null!);

	React.useEffect(() => {
		const handleClick = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				callback();
			}
		};

		document.addEventListener("click", handleClick, true);

		return () => {
			document.removeEventListener("click", handleClick, true);
		};
	}, [ref, callback]);

	return ref;
};

export default useOutsideClick;
